import React from "react";
import Alert from "react-s-alert";
import {useMutation} from "react-query";
import {RouteComponentProps} from "react-router-dom";

import {AdminLayout} from "../../atoms/admin-layout";
import {DocumentTitle} from "../../atoms/document-title";
import {OrganizationForm} from "../../atoms/organization-form";
import {formatDomainNames} from "../../atoms/organization-form/helper";
import {Spinner} from "../../atoms/spinner";
import {OrganizationsService} from "../../services/OrganizationsService";
import {buildResponseErrorMessage} from "../../services/api/errorHelpers";
import {CreateOrganizationRequest} from "../../utils/types/organization";

export const PageAdminOrganizationsCreate = ({
  history,
}: RouteComponentProps) => {
  const {mutate, isLoading} = useMutation(OrganizationsService.create, {
    onMutate: (data: CreateOrganizationRequest) => {
      return Object.assign(data, {domains: formatDomainNames(data.domains)});
    },
    onSuccess: () => {
      Alert.success("Organization was successfully created.");
      history.push("/admin/organizations");
    },
    onError: async resp => {
      Alert.error(
        await buildResponseErrorMessage(resp, "Failed to create organization."),
      );
    },
  });

  return (
    <>
      <DocumentTitle title="Reside - Admin - New Organization" />
      <AdminLayout>
        {!isLoading ? <OrganizationForm onSubmit={mutate} /> : <Spinner />}
      </AdminLayout>
    </>
  );
};
