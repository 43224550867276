import React from "react";
import {css} from "@emotion/css";
import {FormCard, SelectField} from "@reside/ui";
import {OrganizationModel} from "../../../utils/types/organization";

export const OrganizationSelect = ({
  organizations,
}: {
  organizations: readonly OrganizationModel[];
}): JSX.Element => (
  <FormCard title="Organization">
    <SelectField<OrganizationModel>
      getOptionLabel={option => option.name}
      getOptionValue={option => option.id}
      label="Select Organization"
      name="organizationId"
      options={organizations}
      className={css(`
& > select {
padding-left: 0px;
}
`)}
    />
  </FormCard>
);
