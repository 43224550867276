import React from "react";
import Alert from "react-s-alert";
import {useMutation, useQuery} from "react-query";
import {RouteComponentProps} from "react-router-dom";

import {AdminLayoutFullHeightAndTwoCells} from "../../atoms/admin-layout";
import {DocumentTitle} from "../../atoms/document-title";
import {Spinner} from "../../atoms/spinner";
import {OrganizationForm} from "../../atoms/organization-form";
import {formatDomainNames} from "../../atoms/organization-form/helper";
import {OrganizationsService} from "../../services/OrganizationsService";
import {UpdateOrganizationRequest} from "../../utils/types/organization";
import {ApiGatewayError} from "../../utils/types/apiGatewayError";

export const PageAdminOrganizationsEdit = ({
  match,
}: RouteComponentProps<{
  organizationId: string;
}>) => {
  const {organizationId} = match.params;

  const {
    data: organization,
    isLoading,
    refetch,
  } = useQuery(["getOrganization", organizationId], async () => {
    const {data} = await OrganizationsService.find(organizationId);

    return data;
  });

  const {mutate} = useMutation(
    (data: UpdateOrganizationRequest) =>
      OrganizationsService.update(organizationId, data),
    {
      onMutate: (data: UpdateOrganizationRequest) => {
        if (data.domains)
          return Object.assign(data, {
            domains: formatDomainNames(data.domains),
          });
        return data;
      },
      onSuccess: () => {
        Alert.success("Organization was successfully updated.");
        refetch();
      },
      onError: (resp: ApiGatewayError) => {
        Alert.error(resp?.data?.detail || "Failed to update organization.");
      },
    },
  );

  const handleStatusChange = async () => {
    if (organization) {
      mutate({
        status:
          organization.status === "ACTIVE" ? "INACTIVE" : ("ACTIVE" as any),
      });
    }
  };

  const body =
    !isLoading && organization ? (
      <OrganizationForm
        initialValues={{
          ...organization,
          address: {
            ...organization.address,
            state: organization.address.state.id,
          },
        }}
        status={organization.status}
        onStatusChange={handleStatusChange}
        onSubmit={mutate}
      />
    ) : (
      <Spinner />
    );

  return (
    <>
      <DocumentTitle title="Reside - Admin - Edit Organization" />
      <AdminLayoutFullHeightAndTwoCells>
        {body}
      </AdminLayoutFullHeightAndTwoCells>
    </>
  );
};
