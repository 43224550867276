import React, {useState, useCallback, FormEvent, useRef} from "react";
import {useHistory} from "react-router-dom";
import {useQuery} from "react-query";

import {OrganizationsService} from "../../services/OrganizationsService";
import {GeneralSearch} from "../search-box";

import "./AdminOrganizationsSearch.scss";
import {debounce} from "lodash";

export const AdminOrganizationsSearch = () => {
  const history = useHistory();
  const [searchQuery, setSearchQuery] = useState("");
  const [delayedSearch, setDelayedSearch] = useState("");

  const apiCallQueryRef = useRef(
    debounce((query: string) => setDelayedSearch(query), 750),
  );

  const handleInputChange = (event: any) => {
    const query = event.target.value;
    setSearchQuery(query);
    apiCallQueryRef.current(query);
  };

  const {data = [], isLoading} = useQuery(
    ["searchOrganizations", delayedSearch],
    async ({queryKey: [_, query]}) => {
      const {data} = await OrganizationsService.get({query});
      return data.content;
    },
    {enabled: delayedSearch.length > 1},
  );

  const handleSelect = useCallback(
    organisation => {
      history.push(`/admin/organizations/${organisation.id}/edit`);
    },
    [history],
  );

  const reduceItems = () => [
    {
      label: "Organization",
      data: data.map(organization => ({
        id: organization.id,
        value: organization.name,
        description: organization.address.city,
      })),
    },
  ];

  return (
    <GeneralSearch
      placeholder="Search organization"
      items={reduceItems()}
      isSearching={isLoading}
      inputValue={searchQuery}
      onChange={handleSelect}
      onInputChange={handleInputChange}
      onPressSpace={(event: FormEvent<HTMLInputElement>) => {
        if (searchQuery.trim().length > 0) {
          setSearchQuery(`${searchQuery} `);
          apiCallQueryRef.current(`${searchQuery} `);
        }
      }}
      itemToString={item => item?.value || ""}
    />
  );
};
