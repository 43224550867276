import {http} from "./api";
import {http as organizationsHttp} from "./api/restApiClient";
import {ApiResponse} from "apisauce";

import {ImportExportHistory} from "../pages/page-admin-organizations-users/utils/types";
import {
  CreateOrganizationRequest,
  OrganizationModel,
  UpdateOrganizationRequest,
} from "../utils/types/organization";
import {FacilityModel} from "../utils/types/facility";
import {Page} from "./api/pagination";

export const OrganizationsService = {
  // Note: can't use generated endpoint, as we can't pass query to it.
  get: (query: any): Promise<ApiResponse<Page<OrganizationModel>>> =>
    organizationsHttp.get("/organizations", query),

  find: (organizationId: string): Promise<ApiResponse<OrganizationModel>> =>
    organizationsHttp.get(`/organizations/${organizationId}`),

  create: (data: CreateOrganizationRequest): Promise<ApiResponse<OrganizationModel>> =>
    organizationsHttp.post("/organizations", data),

  update: (
    organizationId: string,
    data: UpdateOrganizationRequest,
  ): Promise<ApiResponse<OrganizationModel>> =>
    organizationsHttp.put(`/organizations/${organizationId}`, data),

  getOrganizationFacilities: (
    organizationId: string,
  ): Promise<ApiResponse<FacilityModel[]>> =>
    organizationsHttp.get(`/organizations/${organizationId}/facilities`),

  importExportUsers: {
    getImportExportHistory: (organizationId: string) =>
      http.get<Array<ImportExportHistory>>(
        `import-export/organizations/${organizationId}/users`,
      ),
    uploadFile: (organizationId: string, file: File) => {
      const formData = new FormData();
      formData.append("file", file);

      return http.post(
        `import-export/organizations/${organizationId}/users`,
        formData,
      );
    },
  },
};
