import {
  SimpleFacilityDto,
  CreateFacilityDto,
  FacilityFeatureFlagsDto,
  FacilityControllerApi,
  PatchFacilityDto,
} from "@reside/reside-api-app";
import portableFetch from "portable-fetch";

import {OrganizationsService} from "./OrganizationsService";
import {BASE_PATH} from "../config";
import {PccIntegrationStatus} from "./PccService";
import {getAuthorizationHeader} from "../models/admin-session/selectors";

export const facilityHasPccTurnedOn = (facility: Facility) =>
  facility?.pccIntegrationStatus === PccIntegrationStatus.PccTurnedOn;

export const facilityHasPccUploadActive = (facility: Facility) =>
  Boolean(facility?.pccUploadActive);

export const getFacilityFeatureFlags = (facility: Facility) => ({
  facilityHasPccTurnedOn: facilityHasPccTurnedOn(facility),
  facilityHasPccUploadActive: facilityHasPccUploadActive(facility),
  facilityHasOrgAdminOnlyDeleteAdmissions:
    !!facility.featureFlags?.[
      FacilityFeatureFlagsDto.FeatureFlagsEnum.ORG_ADMIN_ONLY_DELETE_ADMISSIONS
    ],
});

export type Facility = SimpleFacilityDto;

export const FacilityService = {
  findByOrganization: (organizationId: string) =>
    OrganizationsService.getOrganizationFacilities(organizationId),

  create: (facility: CreateFacilityDto) =>
    facilityApi.createFacilityUsingPOST(facility, getAuthorizationHeader()),

  patch: (facilityId: string, fields: PatchFacilityDto) =>
    facilityApi.patchFacilityUsingPATCH(
      fields,
      facilityId,
      getAuthorizationHeader(),
    ),
};

export const facilityApi = new FacilityControllerApi(
  undefined,
  BASE_PATH,
  portableFetch,
);
