"use strict";
/// <reference path="./custom.d.ts" />
// tslint:disable
/**
 * Reside Admission
 * Reside admission micro-service
 *
 * OpenAPI spec version: 0.0.1
 *
 *
 * NOTE: This file is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the file manually.
 */
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.PointClickCareControllerApi = exports.PointClickCareControllerApiFactory = exports.PointClickCareControllerApiFp = exports.PointClickCareControllerApiFetchParamCreator = exports.AdmissionEntryControllerApi = exports.AdmissionEntryControllerApiFactory = exports.AdmissionEntryControllerApiFp = exports.AdmissionEntryControllerApiFetchParamCreator = exports.AdmissionDocumentControllerApi = exports.AdmissionDocumentControllerApiFactory = exports.AdmissionDocumentControllerApiFp = exports.AdmissionDocumentControllerApiFetchParamCreator = exports.ResidentDto = exports.ResidentDetailDto = exports.PccSyncDto = exports.PccActivityLogDto = exports.Pcc = exports.PartialPdfReference = exports.ParametersDto = exports.PageableDto = exports.ChecklistItem = exports.Approval = exports.AnswerOfobject = exports.AdmissionStatusTransition = exports.AdmissionNotificationModel = exports.AdmissionNote = exports.AdmissionFileReference = exports.Admission = exports.RequiredError = exports.BaseAPI = exports.COLLECTION_FORMATS = void 0;
var url = __importStar(require("url"));
var portableFetch = __importStar(require("portable-fetch"));
var BASE_PATH = "//localhost:8082/".replace(/\/+$/, "");
/**
 *
 * @export
 */
exports.COLLECTION_FORMATS = {
    csv: ",",
    ssv: " ",
    tsv: "\t",
    pipes: "|",
};
/**
 *
 * @export
 * @class BaseAPI
 */
var BaseAPI = /** @class */ (function () {
    function BaseAPI(configuration, basePath, fetch) {
        if (basePath === void 0) { basePath = BASE_PATH; }
        if (fetch === void 0) { fetch = portableFetch; }
        this.basePath = basePath;
        this.fetch = fetch;
        if (configuration) {
            this.configuration = configuration;
            this.basePath = configuration.basePath || this.basePath;
        }
    }
    return BaseAPI;
}());
exports.BaseAPI = BaseAPI;
;
/**
 *
 * @export
 * @class RequiredError
 * @extends {Error}
 */
var RequiredError = /** @class */ (function (_super) {
    __extends(RequiredError, _super);
    function RequiredError(field, msg) {
        var _this = _super.call(this, msg) || this;
        _this.field = field;
        return _this;
    }
    return RequiredError;
}(Error));
exports.RequiredError = RequiredError;
/**
 * @export
 * @namespace Admission
 */
var Admission;
(function (Admission) {
    /**
     * @export
     * @enum {string}
     */
    var StatusEnum;
    (function (StatusEnum) {
        StatusEnum["PRE_FLIGHT"] = "PRE_FLIGHT";
        StatusEnum["CANCELLED"] = "CANCELLED";
        StatusEnum["IN_PROGRESS"] = "IN_PROGRESS";
        StatusEnum["SIGNED"] = "SIGNED";
        StatusEnum["APPROVED"] = "APPROVED";
        StatusEnum["ARCHIVED"] = "ARCHIVED";
        StatusEnum["INCOMPLETE"] = "INCOMPLETE";
    })(StatusEnum = Admission.StatusEnum || (Admission.StatusEnum = {}));
})(Admission = exports.Admission || (exports.Admission = {}));
/**
 * @export
 * @namespace AdmissionFileReference
 */
var AdmissionFileReference;
(function (AdmissionFileReference) {
    /**
     * @export
     * @enum {string}
     */
    var PccUploadStatusEnum;
    (function (PccUploadStatusEnum) {
        PccUploadStatusEnum["SUCCESS"] = "SUCCESS";
        PccUploadStatusEnum["PENDING"] = "PENDING";
        PccUploadStatusEnum["ERROR"] = "ERROR";
    })(PccUploadStatusEnum = AdmissionFileReference.PccUploadStatusEnum || (AdmissionFileReference.PccUploadStatusEnum = {}));
    /**
     * @export
     * @enum {string}
     */
    var TypeEnum;
    (function (TypeEnum) {
        TypeEnum["ADMISSION"] = "ADMISSION";
        TypeEnum["PHOTO"] = "PHOTO";
        TypeEnum["TEMPLATE"] = "TEMPLATE";
        TypeEnum["PICTURE"] = "PICTURE";
        TypeEnum["VARIABLE"] = "VARIABLE";
        TypeEnum["ANSWER"] = "ANSWER";
        TypeEnum["PROTECTED"] = "PROTECTED";
        TypeEnum["USER_IMPORT"] = "USER_IMPORT";
        TypeEnum["USER_EXPORT"] = "USER_EXPORT";
    })(TypeEnum = AdmissionFileReference.TypeEnum || (AdmissionFileReference.TypeEnum = {}));
})(AdmissionFileReference = exports.AdmissionFileReference || (exports.AdmissionFileReference = {}));
/**
 * @export
 * @namespace AdmissionNote
 */
var AdmissionNote;
(function (AdmissionNote) {
    /**
     * @export
     * @enum {string}
     */
    var StatusEnum;
    (function (StatusEnum) {
        StatusEnum["NEW"] = "NEW";
        StatusEnum["RESOLVED"] = "RESOLVED";
        StatusEnum["CANCELLED"] = "CANCELLED";
    })(StatusEnum = AdmissionNote.StatusEnum || (AdmissionNote.StatusEnum = {}));
})(AdmissionNote = exports.AdmissionNote || (exports.AdmissionNote = {}));
/**
 * @export
 * @namespace AdmissionNotificationModel
 */
var AdmissionNotificationModel;
(function (AdmissionNotificationModel) {
    /**
     * @export
     * @enum {string}
     */
    var ChannelEnum;
    (function (ChannelEnum) {
        ChannelEnum["EMAIL"] = "EMAIL";
        ChannelEnum["SMS"] = "SMS";
    })(ChannelEnum = AdmissionNotificationModel.ChannelEnum || (AdmissionNotificationModel.ChannelEnum = {}));
    /**
     * @export
     * @enum {string}
     */
    var TypeEnum;
    (function (TypeEnum) {
        TypeEnum["EMERGENCY_CONTACT_INVITATION"] = "EMERGENCY_CONTACT_INVITATION";
        TypeEnum["STAFF_ACCOUNT_CREATED"] = "STAFF_ACCOUNT_CREATED";
        TypeEnum["STAFF_ACCOUNT_CREATED_FOR_RESIDE_ADMIN"] = "STAFF_ACCOUNT_CREATED_FOR_RESIDE_ADMIN";
        TypeEnum["RESET_PASSWORD"] = "RESET_PASSWORD";
        TypeEnum["ADMISSION_IN_PROGRESS"] = "ADMISSION_IN_PROGRESS";
        TypeEnum["ADMISSION_APPROVED_RESIDENT"] = "ADMISSION_APPROVED_RESIDENT";
        TypeEnum["ADMISSION_APPROVED_REPRESENTATIVE"] = "ADMISSION_APPROVED_REPRESENTATIVE";
        TypeEnum["ADMISSION_CANCELLATION"] = "ADMISSION_CANCELLATION";
        TypeEnum["ADMISSION_UPLOADED_TO_PCC"] = "ADMISSION_UPLOADED_TO_PCC";
        TypeEnum["RESIDENT_DEACTIVATED"] = "RESIDENT_DEACTIVATED";
        TypeEnum["ADMISSION_DOCUMENTS_REMINDER_FOR_RESIDENT"] = "ADMISSION_DOCUMENTS_REMINDER_FOR_RESIDENT";
        TypeEnum["ADMISSION_DOCUMENTS_REMINDER_FOR_CLERK"] = "ADMISSION_DOCUMENTS_REMINDER_FOR_CLERK";
        TypeEnum["APPLY_FOR_MEDICARE"] = "APPLY_FOR_MEDICARE";
        TypeEnum["ADVANCED_DIRECTIVE_INFORMATION"] = "ADVANCED_DIRECTIVE_INFORMATION";
        TypeEnum["LAUNDRY_SERVICES_REQUEST"] = "LAUNDRY_SERVICES_REQUEST";
        TypeEnum["ASSIGN_HEALTHCARE_SURROGATE"] = "ASSIGN_HEALTHCARE_SURROGATE";
        TypeEnum["SUPPLEMENTAL_INSURANCE_INFORMATION_REQUEST"] = "SUPPLEMENTAL_INSURANCE_INFORMATION_REQUEST";
        TypeEnum["ADMISSION_SIGNED"] = "ADMISSION_SIGNED";
        TypeEnum["TRUST_FOUND_POLICY_DISAGREEMENT"] = "TRUST_FOUND_POLICY_DISAGREEMENT";
        TypeEnum["SUPPLEMENT_C_DISAGREEMENT"] = "SUPPLEMENT_C_DISAGREEMENT";
        TypeEnum["ARBITRATION_AGREEMENT_NOT_ACCEPTED"] = "ARBITRATION_AGREEMENT_NOT_ACCEPTED";
        TypeEnum["FUNERAL_HOME_NOT_ACCEPTED"] = "FUNERAL_HOME_NOT_ACCEPTED";
        TypeEnum["ADMISSION_DOCUMENTS_REMINDER_FOR_RESIDENT_REPRESENTATIVE"] = "ADMISSION_DOCUMENTS_REMINDER_FOR_RESIDENT_REPRESENTATIVE";
        TypeEnum["FACILITY_STAFF_AFTER_SENT_TO_RESIDENT"] = "FACILITY_STAFF_AFTER_SENT_TO_RESIDENT";
        TypeEnum["ADMISSION_ENTRY_LINK"] = "ADMISSION_ENTRY_LINK";
        TypeEnum["BCBSIL_PACKAGE"] = "BCBSIL_PACKAGE";
        TypeEnum["PCC_API_DAILY_QUOTA_EXCEEDED"] = "PCC_API_DAILY_QUOTA_EXCEEDED";
        TypeEnum["ALTERNATIVE_DISPUTE_RESOLUTION_AGREEMENT_NOT_ACCEPTED"] = "ALTERNATIVE_DISPUTE_RESOLUTION_AGREEMENT_NOT_ACCEPTED";
        TypeEnum["COVID_RIDER_NOT_ACCEPTED"] = "COVID_RIDER_NOT_ACCEPTED";
    })(TypeEnum = AdmissionNotificationModel.TypeEnum || (AdmissionNotificationModel.TypeEnum = {}));
})(AdmissionNotificationModel = exports.AdmissionNotificationModel || (exports.AdmissionNotificationModel = {}));
/**
 * @export
 * @namespace AdmissionStatusTransition
 */
var AdmissionStatusTransition;
(function (AdmissionStatusTransition) {
    /**
     * @export
     * @enum {string}
     */
    var StatusEnum;
    (function (StatusEnum) {
        StatusEnum["PRE_FLIGHT"] = "PRE_FLIGHT";
        StatusEnum["CANCELLED"] = "CANCELLED";
        StatusEnum["IN_PROGRESS"] = "IN_PROGRESS";
        StatusEnum["SIGNED"] = "SIGNED";
        StatusEnum["APPROVED"] = "APPROVED";
        StatusEnum["ARCHIVED"] = "ARCHIVED";
        StatusEnum["INCOMPLETE"] = "INCOMPLETE";
    })(StatusEnum = AdmissionStatusTransition.StatusEnum || (AdmissionStatusTransition.StatusEnum = {}));
})(AdmissionStatusTransition = exports.AdmissionStatusTransition || (exports.AdmissionStatusTransition = {}));
/**
 * @export
 * @namespace AnswerOfobject
 */
var AnswerOfobject;
(function (AnswerOfobject) {
    /**
     * @export
     * @enum {string}
     */
    var QuestionTypeEnum;
    (function (QuestionTypeEnum) {
        QuestionTypeEnum["TEXT"] = "TEXT";
        QuestionTypeEnum["DATE"] = "DATE";
        QuestionTypeEnum["DATETIME"] = "DATETIME";
        QuestionTypeEnum["CHECKBOX"] = "CHECKBOX";
        QuestionTypeEnum["RADIO"] = "RADIO";
        QuestionTypeEnum["SELECT"] = "SELECT";
        QuestionTypeEnum["STATE_SELECT"] = "STATE_SELECT";
        QuestionTypeEnum["SIGNATURE"] = "SIGNATURE";
        QuestionTypeEnum["IMAGE"] = "IMAGE";
        QuestionTypeEnum["TEXTAREA"] = "TEXTAREA";
        QuestionTypeEnum["OBJECT"] = "OBJECT";
        QuestionTypeEnum["LIST"] = "LIST";
        QuestionTypeEnum["FILE"] = "FILE";
        QuestionTypeEnum["CURRENCY"] = "CURRENCY";
        QuestionTypeEnum["TIMEZONE_SELECT"] = "TIMEZONE_SELECT";
    })(QuestionTypeEnum = AnswerOfobject.QuestionTypeEnum || (AnswerOfobject.QuestionTypeEnum = {}));
})(AnswerOfobject = exports.AnswerOfobject || (exports.AnswerOfobject = {}));
/**
 * @export
 * @namespace Approval
 */
var Approval;
(function (Approval) {
    /**
     * @export
     * @enum {string}
     */
    var ApproverTitleEnum;
    (function (ApproverTitleEnum) {
        ApproverTitleEnum["ADMISSIONS_FINANCIAL_SPECIALIST"] = "ADMISSIONS_FINANCIAL_SPECIALIST";
        ApproverTitleEnum["ADMISSIONS_DIRECTOR"] = "ADMISSIONS_DIRECTOR";
        ApproverTitleEnum["FINANCIAL_COORDINATOR"] = "FINANCIAL_COORDINATOR";
        ApproverTitleEnum["ADMINISTRATOR"] = "ADMINISTRATOR";
        ApproverTitleEnum["SOCIAL_SERVICES_DIRECTOR"] = "SOCIAL_SERVICES_DIRECTOR";
        ApproverTitleEnum["LIAISON"] = "LIAISON";
        ApproverTitleEnum["BUSINESS_OFFICE_MANAGER"] = "BUSINESS_OFFICE_MANAGER";
        ApproverTitleEnum["EXECUTIVE_DIRECTOR"] = "EXECUTIVE_DIRECTOR";
        ApproverTitleEnum["LICENSEE"] = "LICENSEE";
        ApproverTitleEnum["GENERAL_MANAGER"] = "GENERAL_MANAGER";
        ApproverTitleEnum["FACILITY_REPRESENTATIVE"] = "FACILITY_REPRESENTATIVE";
    })(ApproverTitleEnum = Approval.ApproverTitleEnum || (Approval.ApproverTitleEnum = {}));
})(Approval = exports.Approval || (exports.Approval = {}));
/**
 * @export
 * @namespace ChecklistItem
 */
var ChecklistItem;
(function (ChecklistItem) {
    /**
     * @export
     * @enum {string}
     */
    var CheckedStatusEnum;
    (function (CheckedStatusEnum) {
        CheckedStatusEnum["OPEN"] = "OPEN";
        CheckedStatusEnum["IN_PROGRESS"] = "IN_PROGRESS";
        CheckedStatusEnum["COMPLETED"] = "COMPLETED";
        CheckedStatusEnum["NOT_APPLICABLE"] = "NOT_APPLICABLE";
    })(CheckedStatusEnum = ChecklistItem.CheckedStatusEnum || (ChecklistItem.CheckedStatusEnum = {}));
    /**
     * @export
     * @enum {string}
     */
    var MandatoryForStatusesEnum;
    (function (MandatoryForStatusesEnum) {
        MandatoryForStatusesEnum["PRE_FLIGHT"] = "PRE_FLIGHT";
        MandatoryForStatusesEnum["CANCELLED"] = "CANCELLED";
        MandatoryForStatusesEnum["IN_PROGRESS"] = "IN_PROGRESS";
        MandatoryForStatusesEnum["SIGNED"] = "SIGNED";
        MandatoryForStatusesEnum["APPROVED"] = "APPROVED";
        MandatoryForStatusesEnum["ARCHIVED"] = "ARCHIVED";
        MandatoryForStatusesEnum["INCOMPLETE"] = "INCOMPLETE";
    })(MandatoryForStatusesEnum = ChecklistItem.MandatoryForStatusesEnum || (ChecklistItem.MandatoryForStatusesEnum = {}));
})(ChecklistItem = exports.ChecklistItem || (exports.ChecklistItem = {}));
/**
 * @export
 * @namespace PageableDto
 */
var PageableDto;
(function (PageableDto) {
    /**
     * @export
     * @enum {string}
     */
    var DirectionEnum;
    (function (DirectionEnum) {
        DirectionEnum["ASC"] = "ASC";
        DirectionEnum["DESC"] = "DESC";
    })(DirectionEnum = PageableDto.DirectionEnum || (PageableDto.DirectionEnum = {}));
})(PageableDto = exports.PageableDto || (exports.PageableDto = {}));
/**
 * @export
 * @namespace ParametersDto
 */
var ParametersDto;
(function (ParametersDto) {
    /**
     * @export
     * @enum {string}
     */
    var StatusesEnum;
    (function (StatusesEnum) {
        StatusesEnum["PRE_FLIGHT"] = "PRE_FLIGHT";
        StatusesEnum["CANCELLED"] = "CANCELLED";
        StatusesEnum["IN_PROGRESS"] = "IN_PROGRESS";
        StatusesEnum["SIGNED"] = "SIGNED";
        StatusesEnum["APPROVED"] = "APPROVED";
        StatusesEnum["ARCHIVED"] = "ARCHIVED";
        StatusesEnum["INCOMPLETE"] = "INCOMPLETE";
    })(StatusesEnum = ParametersDto.StatusesEnum || (ParametersDto.StatusesEnum = {}));
})(ParametersDto = exports.ParametersDto || (exports.ParametersDto = {}));
/**
 * @export
 * @namespace PartialPdfReference
 */
var PartialPdfReference;
(function (PartialPdfReference) {
    /**
     * @export
     * @enum {string}
     */
    var PccUploadStatusEnum;
    (function (PccUploadStatusEnum) {
        PccUploadStatusEnum["SUCCESS"] = "SUCCESS";
        PccUploadStatusEnum["PENDING"] = "PENDING";
        PccUploadStatusEnum["ERROR"] = "ERROR";
    })(PccUploadStatusEnum = PartialPdfReference.PccUploadStatusEnum || (PartialPdfReference.PccUploadStatusEnum = {}));
    /**
     * @export
     * @enum {string}
     */
    var TypeEnum;
    (function (TypeEnum) {
        TypeEnum["ADMISSION"] = "ADMISSION";
        TypeEnum["PHOTO"] = "PHOTO";
        TypeEnum["TEMPLATE"] = "TEMPLATE";
        TypeEnum["PICTURE"] = "PICTURE";
        TypeEnum["VARIABLE"] = "VARIABLE";
        TypeEnum["ANSWER"] = "ANSWER";
        TypeEnum["PROTECTED"] = "PROTECTED";
        TypeEnum["USER_IMPORT"] = "USER_IMPORT";
        TypeEnum["USER_EXPORT"] = "USER_EXPORT";
    })(TypeEnum = PartialPdfReference.TypeEnum || (PartialPdfReference.TypeEnum = {}));
})(PartialPdfReference = exports.PartialPdfReference || (exports.PartialPdfReference = {}));
/**
 * @export
 * @namespace Pcc
 */
var Pcc;
(function (Pcc) {
    /**
     * @export
     * @enum {string}
     */
    var RelatedAdmissionStatusEnum;
    (function (RelatedAdmissionStatusEnum) {
        RelatedAdmissionStatusEnum["PRE_FLIGHT"] = "PRE_FLIGHT";
        RelatedAdmissionStatusEnum["CANCELLED"] = "CANCELLED";
        RelatedAdmissionStatusEnum["IN_PROGRESS"] = "IN_PROGRESS";
        RelatedAdmissionStatusEnum["SIGNED"] = "SIGNED";
        RelatedAdmissionStatusEnum["APPROVED"] = "APPROVED";
        RelatedAdmissionStatusEnum["ARCHIVED"] = "ARCHIVED";
        RelatedAdmissionStatusEnum["INCOMPLETE"] = "INCOMPLETE";
    })(RelatedAdmissionStatusEnum = Pcc.RelatedAdmissionStatusEnum || (Pcc.RelatedAdmissionStatusEnum = {}));
})(Pcc = exports.Pcc || (exports.Pcc = {}));
/**
 * @export
 * @namespace PccActivityLogDto
 */
var PccActivityLogDto;
(function (PccActivityLogDto) {
    /**
     * @export
     * @enum {string}
     */
    var ErrorCodeEnum;
    (function (ErrorCodeEnum) {
        ErrorCodeEnum["ACCOUNT"] = "MISSING_PATIENT_ACCOUNT";
    })(ErrorCodeEnum = PccActivityLogDto.ErrorCodeEnum || (PccActivityLogDto.ErrorCodeEnum = {}));
    /**
     * @export
     * @enum {string}
     */
    var EventTypeEnum;
    (function (EventTypeEnum) {
        EventTypeEnum["CREATE_PATIENT"] = "CREATE_PATIENT";
        EventTypeEnum["UPLOAD_DOCUMENT"] = "UPLOAD_DOCUMENT";
        EventTypeEnum["UPLOAD_ADMISSION_PACKET"] = "UPLOAD_ADMISSION_PACKET";
        EventTypeEnum["EVENT_LISTENER_ACTIVATE_MAPPING"] = "EVENT_LISTENER_ACTIVATE_MAPPING";
        EventTypeEnum["EVENT_LISTENER_DELETE_MAPPING"] = "EVENT_LISTENER_DELETE_MAPPING";
    })(EventTypeEnum = PccActivityLogDto.EventTypeEnum || (PccActivityLogDto.EventTypeEnum = {}));
    /**
     * @export
     * @enum {string}
     */
    var StatusEnum;
    (function (StatusEnum) {
        StatusEnum["SUCCESS"] = "SUCCESS";
        StatusEnum["FAILED"] = "FAILED";
        StatusEnum["SKIPPED"] = "SKIPPED";
        StatusEnum["PENDING"] = "PENDING";
    })(StatusEnum = PccActivityLogDto.StatusEnum || (PccActivityLogDto.StatusEnum = {}));
})(PccActivityLogDto = exports.PccActivityLogDto || (exports.PccActivityLogDto = {}));
/**
 * @export
 * @namespace PccSyncDto
 */
var PccSyncDto;
(function (PccSyncDto) {
    /**
     * @export
     * @enum {string}
     */
    var EventTypeEnum;
    (function (EventTypeEnum) {
        EventTypeEnum["PendingPatientAccepted"] = "patient.pendingPatientAccepted";
        EventTypeEnum["Admit"] = "patient.admit";
        EventTypeEnum["Readmit"] = "patient.readmit";
        EventTypeEnum["PreAdmit"] = "patient.preAdmit";
        EventTypeEnum["PreReadmit"] = "patient.preReadmit";
    })(EventTypeEnum = PccSyncDto.EventTypeEnum || (PccSyncDto.EventTypeEnum = {}));
})(PccSyncDto = exports.PccSyncDto || (exports.PccSyncDto = {}));
/**
 * @export
 * @namespace ResidentDetailDto
 */
var ResidentDetailDto;
(function (ResidentDetailDto) {
    /**
     * @export
     * @enum {string}
     */
    var GenderEnum;
    (function (GenderEnum) {
        GenderEnum["MALE"] = "MALE";
        GenderEnum["FEMALE"] = "FEMALE";
        GenderEnum["UNKNOWN"] = "UNKNOWN";
    })(GenderEnum = ResidentDetailDto.GenderEnum || (ResidentDetailDto.GenderEnum = {}));
})(ResidentDetailDto = exports.ResidentDetailDto || (exports.ResidentDetailDto = {}));
/**
 * @export
 * @namespace ResidentDto
 */
var ResidentDto;
(function (ResidentDto) {
    /**
     * @export
     * @enum {string}
     */
    var GenderEnum;
    (function (GenderEnum) {
        GenderEnum["MALE"] = "MALE";
        GenderEnum["FEMALE"] = "FEMALE";
        GenderEnum["UNKNOWN"] = "UNKNOWN";
    })(GenderEnum = ResidentDto.GenderEnum || (ResidentDto.GenderEnum = {}));
})(ResidentDto = exports.ResidentDto || (exports.ResidentDto = {}));
/**
 * AdmissionDocumentControllerApi - fetch parameter creator
 * @export
 */
var AdmissionDocumentControllerApiFetchParamCreator = function (configuration) {
    return {
        /**
         *
         * @summary Full administrator view of an admission
         * @param {string} admissionId admissionId
         * @param {string} [authorization] Authorization Header
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminAdmissionUsingGET: function (admissionId, authorization, options) {
            if (options === void 0) { options = {}; }
            // verify required parameter 'admissionId' is not null or undefined
            if (admissionId === null || admissionId === undefined) {
                throw new RequiredError('admissionId', 'Required parameter admissionId was null or undefined when calling adminAdmissionUsingGET.');
            }
            var localVarPath = "/api/v3/admissions/{admissionId}/administration"
                .replace("{".concat("admissionId", "}"), encodeURIComponent(String(admissionId)));
            var localVarUrlObj = url.parse(localVarPath, true);
            var localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            var localVarHeaderParameter = {};
            var localVarQueryParameter = {};
            if (authorization !== undefined && authorization !== null) {
                localVarHeaderParameter['Authorization'] = String(authorization);
            }
            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary Approve an admission
         * @param {Admission} body admission
         * @param {string} admissionId admissionId
         * @param {string} [authorization] Authorization Header
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        approveAdmissionUsingPATCH: function (body, admissionId, authorization, options) {
            if (options === void 0) { options = {}; }
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body', 'Required parameter body was null or undefined when calling approveAdmissionUsingPATCH.');
            }
            // verify required parameter 'admissionId' is not null or undefined
            if (admissionId === null || admissionId === undefined) {
                throw new RequiredError('admissionId', 'Required parameter admissionId was null or undefined when calling approveAdmissionUsingPATCH.');
            }
            var localVarPath = "/api/v3/admissions/{admissionId}/approve"
                .replace("{".concat("admissionId", "}"), encodeURIComponent(String(admissionId)));
            var localVarUrlObj = url.parse(localVarPath, true);
            var localVarRequestOptions = Object.assign({ method: 'PATCH' }, options);
            var localVarHeaderParameter = {};
            var localVarQueryParameter = {};
            if (authorization !== undefined && authorization !== null) {
                localVarHeaderParameter['Authorization'] = String(authorization);
            }
            localVarHeaderParameter['Content-Type'] = 'application/json';
            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            var needsSerialization = ("Admission" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body = needsSerialization ? JSON.stringify(body || {}) : (body || "");
            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary Archive an admission
         * @param {string} admissionId admissionId
         * @param {string} [authorization] Authorization Header
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        archiveAdmissionUsingPATCH: function (admissionId, authorization, options) {
            if (options === void 0) { options = {}; }
            // verify required parameter 'admissionId' is not null or undefined
            if (admissionId === null || admissionId === undefined) {
                throw new RequiredError('admissionId', 'Required parameter admissionId was null or undefined when calling archiveAdmissionUsingPATCH.');
            }
            var localVarPath = "/api/v3/admissions/{admissionId}/archive"
                .replace("{".concat("admissionId", "}"), encodeURIComponent(String(admissionId)));
            var localVarUrlObj = url.parse(localVarPath, true);
            var localVarRequestOptions = Object.assign({ method: 'PATCH' }, options);
            var localVarHeaderParameter = {};
            var localVarQueryParameter = {};
            if (authorization !== undefined && authorization !== null) {
                localVarHeaderParameter['Authorization'] = String(authorization);
            }
            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary Creates admission flag within the admission
         * @param {AdmissionFlag} body admissionFlag
         * @param {string} admissionId admissionId
         * @param {string} [authorization] Authorization Header
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createAdmissionFlagUsingPOST: function (body, admissionId, authorization, options) {
            if (options === void 0) { options = {}; }
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body', 'Required parameter body was null or undefined when calling createAdmissionFlagUsingPOST.');
            }
            // verify required parameter 'admissionId' is not null or undefined
            if (admissionId === null || admissionId === undefined) {
                throw new RequiredError('admissionId', 'Required parameter admissionId was null or undefined when calling createAdmissionFlagUsingPOST.');
            }
            var localVarPath = "/api/v3/admissions/{admissionId}/flags"
                .replace("{".concat("admissionId", "}"), encodeURIComponent(String(admissionId)));
            var localVarUrlObj = url.parse(localVarPath, true);
            var localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            var localVarHeaderParameter = {};
            var localVarQueryParameter = {};
            if (authorization !== undefined && authorization !== null) {
                localVarHeaderParameter['Authorization'] = String(authorization);
            }
            localVarHeaderParameter['Content-Type'] = 'application/json';
            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            var needsSerialization = ("AdmissionFlag" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body = needsSerialization ? JSON.stringify(body || {}) : (body || "");
            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary Create a new admission
         * @param {Admission} body admissionData
         * @param {string} [authorization] Authorization Header
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createAdmissionUsingPOST: function (body, authorization, options) {
            if (options === void 0) { options = {}; }
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body', 'Required parameter body was null or undefined when calling createAdmissionUsingPOST.');
            }
            var localVarPath = "/api/v3/admissions";
            var localVarUrlObj = url.parse(localVarPath, true);
            var localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            var localVarHeaderParameter = {};
            var localVarQueryParameter = {};
            if (authorization !== undefined && authorization !== null) {
                localVarHeaderParameter['Authorization'] = String(authorization);
            }
            localVarHeaderParameter['Content-Type'] = 'application/json';
            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            var needsSerialization = ("Admission" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body = needsSerialization ? JSON.stringify(body || {}) : (body || "");
            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary delete admission flag
         * @param {string} admissionId admissionId
         * @param {string} flagId flagId
         * @param {string} [authorization] Authorization Header
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteAdmissionFlagUsingDELETE: function (admissionId, flagId, authorization, options) {
            if (options === void 0) { options = {}; }
            // verify required parameter 'admissionId' is not null or undefined
            if (admissionId === null || admissionId === undefined) {
                throw new RequiredError('admissionId', 'Required parameter admissionId was null or undefined when calling deleteAdmissionFlagUsingDELETE.');
            }
            // verify required parameter 'flagId' is not null or undefined
            if (flagId === null || flagId === undefined) {
                throw new RequiredError('flagId', 'Required parameter flagId was null or undefined when calling deleteAdmissionFlagUsingDELETE.');
            }
            var localVarPath = "/api/v3/admissions/{admissionId}/flags/{flagId}"
                .replace("{".concat("admissionId", "}"), encodeURIComponent(String(admissionId)))
                .replace("{".concat("flagId", "}"), encodeURIComponent(String(flagId)));
            var localVarUrlObj = url.parse(localVarPath, true);
            var localVarRequestOptions = Object.assign({ method: 'DELETE' }, options);
            var localVarHeaderParameter = {};
            var localVarQueryParameter = {};
            if (authorization !== undefined && authorization !== null) {
                localVarHeaderParameter['Authorization'] = String(authorization);
            }
            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary Cancel an admission
         * @param {AdmissionCancellationDto} body admissionCancellationDto
         * @param {string} admissionId admissionId
         * @param {string} [authorization] Authorization Header
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteAdmissionUsingPATCH: function (body, admissionId, authorization, options) {
            if (options === void 0) { options = {}; }
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body', 'Required parameter body was null or undefined when calling deleteAdmissionUsingPATCH.');
            }
            // verify required parameter 'admissionId' is not null or undefined
            if (admissionId === null || admissionId === undefined) {
                throw new RequiredError('admissionId', 'Required parameter admissionId was null or undefined when calling deleteAdmissionUsingPATCH.');
            }
            var localVarPath = "/api/v3/admissions/{admissionId}/delete"
                .replace("{".concat("admissionId", "}"), encodeURIComponent(String(admissionId)));
            var localVarUrlObj = url.parse(localVarPath, true);
            var localVarRequestOptions = Object.assign({ method: 'PATCH' }, options);
            var localVarHeaderParameter = {};
            var localVarQueryParameter = {};
            if (authorization !== undefined && authorization !== null) {
                localVarHeaderParameter['Authorization'] = String(authorization);
            }
            localVarHeaderParameter['Content-Type'] = 'application/json';
            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            var needsSerialization = ("AdmissionCancellationDto" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body = needsSerialization ? JSON.stringify(body || {}) : (body || "");
            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary Process discharged PCC resident webhook
         * @param {PccDischargeResidentDto} body pccDischargeResidentDto
         * @param {string} [authorization] Authorization Header
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        dischargeResidentPccUsingPOST: function (body, authorization, options) {
            if (options === void 0) { options = {}; }
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body', 'Required parameter body was null or undefined when calling dischargeResidentPccUsingPOST.');
            }
            var localVarPath = "/api/v3/admissions/discharge-resident-pcc";
            var localVarUrlObj = url.parse(localVarPath, true);
            var localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            var localVarHeaderParameter = {};
            var localVarQueryParameter = {};
            if (authorization !== undefined && authorization !== null) {
                localVarHeaderParameter['Authorization'] = String(authorization);
            }
            localVarHeaderParameter['Content-Type'] = 'application/json';
            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            var needsSerialization = ("PccDischargeResidentDto" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body = needsSerialization ? JSON.stringify(body || {}) : (body || "");
            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary Download generated PDF of given admission based on download or access token
         * @param {string} admissionId admissionId
         * @param {string} groupKey groupKey
         * @param {string} [authorization] Authorization Header
         * @param {string} [accessToken] accessToken
         * @param {string} [token] token
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        downloadPartialPdfFromS3UsingGET: function (admissionId, groupKey, authorization, accessToken, token, options) {
            if (options === void 0) { options = {}; }
            // verify required parameter 'admissionId' is not null or undefined
            if (admissionId === null || admissionId === undefined) {
                throw new RequiredError('admissionId', 'Required parameter admissionId was null or undefined when calling downloadPartialPdfFromS3UsingGET.');
            }
            // verify required parameter 'groupKey' is not null or undefined
            if (groupKey === null || groupKey === undefined) {
                throw new RequiredError('groupKey', 'Required parameter groupKey was null or undefined when calling downloadPartialPdfFromS3UsingGET.');
            }
            var localVarPath = "/api/v3/admissions/{admissionId}/download-pdf/{groupKey}"
                .replace("{".concat("admissionId", "}"), encodeURIComponent(String(admissionId)))
                .replace("{".concat("groupKey", "}"), encodeURIComponent(String(groupKey)));
            var localVarUrlObj = url.parse(localVarPath, true);
            var localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            var localVarHeaderParameter = {};
            var localVarQueryParameter = {};
            if (accessToken !== undefined) {
                localVarQueryParameter['accessToken'] = accessToken;
            }
            if (token !== undefined) {
                localVarQueryParameter['token'] = token;
            }
            if (authorization !== undefined && authorization !== null) {
                localVarHeaderParameter['Authorization'] = String(authorization);
            }
            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary Download generated PDF of given admission based on download or access token
         * @param {string} admissionId admissionId
         * @param {string} [authorization] Authorization Header
         * @param {string} [accessToken] accessToken
         * @param {string} [token] token
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        downloadPdfFromS3UsingGET: function (admissionId, authorization, accessToken, token, options) {
            if (options === void 0) { options = {}; }
            // verify required parameter 'admissionId' is not null or undefined
            if (admissionId === null || admissionId === undefined) {
                throw new RequiredError('admissionId', 'Required parameter admissionId was null or undefined when calling downloadPdfFromS3UsingGET.');
            }
            var localVarPath = "/api/v3/admissions/{admissionId}/download-pdf"
                .replace("{".concat("admissionId", "}"), encodeURIComponent(String(admissionId)));
            var localVarUrlObj = url.parse(localVarPath, true);
            var localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            var localVarHeaderParameter = {};
            var localVarQueryParameter = {};
            if (accessToken !== undefined) {
                localVarQueryParameter['accessToken'] = accessToken;
            }
            if (token !== undefined) {
                localVarQueryParameter['token'] = token;
            }
            if (authorization !== undefined && authorization !== null) {
                localVarHeaderParameter['Authorization'] = String(authorization);
            }
            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary get all admission flags
         * @param {string} admissionId admissionId
         * @param {string} [authorization] Authorization Header
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAdmissionFlagsUsingGET: function (admissionId, authorization, options) {
            if (options === void 0) { options = {}; }
            // verify required parameter 'admissionId' is not null or undefined
            if (admissionId === null || admissionId === undefined) {
                throw new RequiredError('admissionId', 'Required parameter admissionId was null or undefined when calling getAdmissionFlagsUsingGET.');
            }
            var localVarPath = "/api/v3/admissions/{admissionId}/flags"
                .replace("{".concat("admissionId", "}"), encodeURIComponent(String(admissionId)));
            var localVarUrlObj = url.parse(localVarPath, true);
            var localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            var localVarHeaderParameter = {};
            var localVarQueryParameter = {};
            if (authorization !== undefined && authorization !== null) {
                localVarHeaderParameter['Authorization'] = String(authorization);
            }
            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary Get an admission without postprocessing for internal calls
         * @param {string} admissionId admissionId
         * @param {string} [authorization] Authorization Header
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAdmissionInternalUsingGET: function (admissionId, authorization, options) {
            if (options === void 0) { options = {}; }
            // verify required parameter 'admissionId' is not null or undefined
            if (admissionId === null || admissionId === undefined) {
                throw new RequiredError('admissionId', 'Required parameter admissionId was null or undefined when calling getAdmissionInternalUsingGET.');
            }
            var localVarPath = "/api/v3/admissions/{admissionId}/internal"
                .replace("{".concat("admissionId", "}"), encodeURIComponent(String(admissionId)));
            var localVarUrlObj = url.parse(localVarPath, true);
            var localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            var localVarHeaderParameter = {};
            var localVarQueryParameter = {};
            if (authorization !== undefined && authorization !== null) {
                localVarHeaderParameter['Authorization'] = String(authorization);
            }
            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary get all admission status transitions
         * @param {string} admissionId admissionId
         * @param {string} [authorization] Authorization Header
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAdmissionTransitionsUsingGET: function (admissionId, authorization, options) {
            if (options === void 0) { options = {}; }
            // verify required parameter 'admissionId' is not null or undefined
            if (admissionId === null || admissionId === undefined) {
                throw new RequiredError('admissionId', 'Required parameter admissionId was null or undefined when calling getAdmissionTransitionsUsingGET.');
            }
            var localVarPath = "/api/v3/admissions/{admissionId}/transitions"
                .replace("{".concat("admissionId", "}"), encodeURIComponent(String(admissionId)));
            var localVarUrlObj = url.parse(localVarPath, true);
            var localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            var localVarHeaderParameter = {};
            var localVarQueryParameter = {};
            if (authorization !== undefined && authorization !== null) {
                localVarHeaderParameter['Authorization'] = String(authorization);
            }
            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary Get an admission
         * @param {string} admissionId admissionId
         * @param {string} [authorization] Authorization Header
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAdmissionUsingGET: function (admissionId, authorization, options) {
            if (options === void 0) { options = {}; }
            // verify required parameter 'admissionId' is not null or undefined
            if (admissionId === null || admissionId === undefined) {
                throw new RequiredError('admissionId', 'Required parameter admissionId was null or undefined when calling getAdmissionUsingGET.');
            }
            var localVarPath = "/api/v3/admissions/{admissionId}"
                .replace("{".concat("admissionId", "}"), encodeURIComponent(String(admissionId)));
            var localVarUrlObj = url.parse(localVarPath, true);
            var localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            var localVarHeaderParameter = {};
            var localVarQueryParameter = {};
            if (authorization !== undefined && authorization !== null) {
                localVarHeaderParameter['Authorization'] = String(authorization);
            }
            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary Retrieve preview for admission
         * @param {string} admissionId admissionId
         * @param {string} [authorization] Authorization Header
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPreviewUsingGET: function (admissionId, authorization, options) {
            if (options === void 0) { options = {}; }
            // verify required parameter 'admissionId' is not null or undefined
            if (admissionId === null || admissionId === undefined) {
                throw new RequiredError('admissionId', 'Required parameter admissionId was null or undefined when calling getPreviewUsingGET.');
            }
            var localVarPath = "/api/v3/admissions/{admissionId}/preview"
                .replace("{".concat("admissionId", "}"), encodeURIComponent(String(admissionId)));
            var localVarUrlObj = url.parse(localVarPath, true);
            var localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            var localVarHeaderParameter = {};
            var localVarQueryParameter = {};
            if (authorization !== undefined && authorization !== null) {
                localVarHeaderParameter['Authorization'] = String(authorization);
            }
            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary Update admissions
         * @param {AdmissionMigrationDto} body admissionMigrationDto
         * @param {string} [authorization] Authorization Header
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        migrateAdmissionsTemplateUsingPOST: function (body, authorization, options) {
            if (options === void 0) { options = {}; }
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body', 'Required parameter body was null or undefined when calling migrateAdmissionsTemplateUsingPOST.');
            }
            var localVarPath = "/api/v3/admissions/migrate";
            var localVarUrlObj = url.parse(localVarPath, true);
            var localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            var localVarHeaderParameter = {};
            var localVarQueryParameter = {};
            if (authorization !== undefined && authorization !== null) {
                localVarHeaderParameter['Authorization'] = String(authorization);
            }
            localVarHeaderParameter['Content-Type'] = 'application/json';
            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            var needsSerialization = ("AdmissionMigrationDto" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body = needsSerialization ? JSON.stringify(body || {}) : (body || "");
            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary Patch admission with PatchAdmissionDto. RESIDENT cannot patch Answers and CompletionPercentage if admission is already SIGNED, but CLERK and ADMINISTRATOR can.
         * @param {PatchAdmissionDto} body patchAdmissionDto
         * @param {string} admissionId admissionId
         * @param {string} [authorization] Authorization Header
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patchAdmissionUsingPATCH: function (body, admissionId, authorization, options) {
            if (options === void 0) { options = {}; }
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body', 'Required parameter body was null or undefined when calling patchAdmissionUsingPATCH.');
            }
            // verify required parameter 'admissionId' is not null or undefined
            if (admissionId === null || admissionId === undefined) {
                throw new RequiredError('admissionId', 'Required parameter admissionId was null or undefined when calling patchAdmissionUsingPATCH.');
            }
            var localVarPath = "/api/v3/admissions/{admissionId}"
                .replace("{".concat("admissionId", "}"), encodeURIComponent(String(admissionId)));
            var localVarUrlObj = url.parse(localVarPath, true);
            var localVarRequestOptions = Object.assign({ method: 'PATCH' }, options);
            var localVarHeaderParameter = {};
            var localVarQueryParameter = {};
            if (authorization !== undefined && authorization !== null) {
                localVarHeaderParameter['Authorization'] = String(authorization);
            }
            localVarHeaderParameter['Content-Type'] = 'application/json';
            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            var needsSerialization = ("PatchAdmissionDto" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body = needsSerialization ? JSON.stringify(body || {}) : (body || "");
            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary Patch checklist item identified by ID in given admission identified by its ID
         * @param {ChecklistItem} body checklistItem
         * @param {string} admissionId admissionId
         * @param {string} checklistItemId checklistItemId
         * @param {string} [authorization] Authorization Header
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patchChecklistItemUsingPATCH: function (body, admissionId, checklistItemId, authorization, options) {
            if (options === void 0) { options = {}; }
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body', 'Required parameter body was null or undefined when calling patchChecklistItemUsingPATCH.');
            }
            // verify required parameter 'admissionId' is not null or undefined
            if (admissionId === null || admissionId === undefined) {
                throw new RequiredError('admissionId', 'Required parameter admissionId was null or undefined when calling patchChecklistItemUsingPATCH.');
            }
            // verify required parameter 'checklistItemId' is not null or undefined
            if (checklistItemId === null || checklistItemId === undefined) {
                throw new RequiredError('checklistItemId', 'Required parameter checklistItemId was null or undefined when calling patchChecklistItemUsingPATCH.');
            }
            var localVarPath = "/api/v3/admissions/{admissionId}/checklist-items/{checklistItemId}"
                .replace("{".concat("admissionId", "}"), encodeURIComponent(String(admissionId)))
                .replace("{".concat("checklistItemId", "}"), encodeURIComponent(String(checklistItemId)));
            var localVarUrlObj = url.parse(localVarPath, true);
            var localVarRequestOptions = Object.assign({ method: 'PATCH' }, options);
            var localVarHeaderParameter = {};
            var localVarQueryParameter = {};
            if (authorization !== undefined && authorization !== null) {
                localVarHeaderParameter['Authorization'] = String(authorization);
            }
            localVarHeaderParameter['Content-Type'] = 'application/json';
            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            var needsSerialization = ("ChecklistItem" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body = needsSerialization ? JSON.stringify(body || {}) : (body || "");
            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary Process admission with pending PCC account
         * @param {AdmissionWithPendingPatientDto} body admissionWithPendingPatientDto
         * @param {string} [authorization] Authorization Header
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        processAdmissionWithPendingPccAccountUsingPOST: function (body, authorization, options) {
            if (options === void 0) { options = {}; }
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body', 'Required parameter body was null or undefined when calling processAdmissionWithPendingPccAccountUsingPOST.');
            }
            var localVarPath = "/api/v3/admissions/process-admission-with-pending-pcc-account";
            var localVarUrlObj = url.parse(localVarPath, true);
            var localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            var localVarHeaderParameter = {};
            var localVarQueryParameter = {};
            if (authorization !== undefined && authorization !== null) {
                localVarHeaderParameter['Authorization'] = String(authorization);
            }
            localVarHeaderParameter['Content-Type'] = 'application/json';
            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            var needsSerialization = ("AdmissionWithPendingPatientDto" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body = needsSerialization ? JSON.stringify(body || {}) : (body || "");
            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary Restore cancelled admission
         * @param {string} admissionId admissionId
         * @param {string} [authorization] Authorization Header
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        restoreAdmissionUsingPATCH: function (admissionId, authorization, options) {
            if (options === void 0) { options = {}; }
            // verify required parameter 'admissionId' is not null or undefined
            if (admissionId === null || admissionId === undefined) {
                throw new RequiredError('admissionId', 'Required parameter admissionId was null or undefined when calling restoreAdmissionUsingPATCH.');
            }
            var localVarPath = "/api/v3/admissions/{admissionId}/restore"
                .replace("{".concat("admissionId", "}"), encodeURIComponent(String(admissionId)));
            var localVarUrlObj = url.parse(localVarPath, true);
            var localVarRequestOptions = Object.assign({ method: 'PATCH' }, options);
            var localVarHeaderParameter = {};
            var localVarQueryParameter = {};
            if (authorization !== undefined && authorization !== null) {
                localVarHeaderParameter['Authorization'] = String(authorization);
            }
            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary Reupload the admission PDF contract files
         * @param {string} admissionId admissionId
         * @param {string} [authorization] Authorization Header
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        reuploadContractUsingGET: function (admissionId, authorization, options) {
            if (options === void 0) { options = {}; }
            // verify required parameter 'admissionId' is not null or undefined
            if (admissionId === null || admissionId === undefined) {
                throw new RequiredError('admissionId', 'Required parameter admissionId was null or undefined when calling reuploadContractUsingGET.');
            }
            var localVarPath = "/api/v3/admissions/{admissionId}/reupload"
                .replace("{".concat("admissionId", "}"), encodeURIComponent(String(admissionId)));
            var localVarUrlObj = url.parse(localVarPath, true);
            var localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            var localVarHeaderParameter = {};
            var localVarQueryParameter = {};
            if (authorization !== undefined && authorization !== null) {
                localVarHeaderParameter['Authorization'] = String(authorization);
            }
            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary Patch admission with PatchAdmissionDto. RESIDENT can patch only Answers which are on slides marked as afterAdmission if admission is already SIGNED.
         * @param {PatchAdmissionDto} body patchAdmissionDto
         * @param {string} admissionId admissionId
         * @param {string} [authorization] Authorization Header
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        saveAfterAdmissionAnswersUsingPATCH: function (body, admissionId, authorization, options) {
            if (options === void 0) { options = {}; }
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body', 'Required parameter body was null or undefined when calling saveAfterAdmissionAnswersUsingPATCH.');
            }
            // verify required parameter 'admissionId' is not null or undefined
            if (admissionId === null || admissionId === undefined) {
                throw new RequiredError('admissionId', 'Required parameter admissionId was null or undefined when calling saveAfterAdmissionAnswersUsingPATCH.');
            }
            var localVarPath = "/api/v3/admissions/{admissionId}/after-admission"
                .replace("{".concat("admissionId", "}"), encodeURIComponent(String(admissionId)));
            var localVarUrlObj = url.parse(localVarPath, true);
            var localVarRequestOptions = Object.assign({ method: 'PATCH' }, options);
            var localVarHeaderParameter = {};
            var localVarQueryParameter = {};
            if (authorization !== undefined && authorization !== null) {
                localVarHeaderParameter['Authorization'] = String(authorization);
            }
            localVarHeaderParameter['Content-Type'] = 'application/json';
            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            var needsSerialization = ("PatchAdmissionDto" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body = needsSerialization ? JSON.stringify(body || {}) : (body || "");
            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary Search admissions. Returns Page<AdmissionPreview>
         * @param {ParametersDto} body body
         * @param {string} [authorization] Authorization Header
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchAdmissionUsingPOST: function (body, authorization, options) {
            if (options === void 0) { options = {}; }
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body', 'Required parameter body was null or undefined when calling searchAdmissionUsingPOST.');
            }
            var localVarPath = "/api/v3/admissions/search";
            var localVarUrlObj = url.parse(localVarPath, true);
            var localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            var localVarHeaderParameter = {};
            var localVarQueryParameter = {};
            if (authorization !== undefined && authorization !== null) {
                localVarHeaderParameter['Authorization'] = String(authorization);
            }
            localVarHeaderParameter['Content-Type'] = 'application/json';
            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            var needsSerialization = ("ParametersDto" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body = needsSerialization ? JSON.stringify(body || {}) : (body || "");
            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary Search admissions by query resident name or code or resident code. Returns Page<AdmissionPreview>
         * @param {string} query query
         * @param {string} [authorization] Authorization Header
         * @param {number} [page]
         * @param {number} [size]
         * @param {ReadonlyArray<string>} [sort]
         * @param {ReadonlyArray<string>} [status] status
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchAdmissionsByQueryUsingGET: function (query, authorization, page, size, sort, status, options) {
            if (options === void 0) { options = {}; }
            // verify required parameter 'query' is not null or undefined
            if (query === null || query === undefined) {
                throw new RequiredError('query', 'Required parameter query was null or undefined when calling searchAdmissionsByQueryUsingGET.');
            }
            var localVarPath = "/api/v3/admissions/search";
            var localVarUrlObj = url.parse(localVarPath, true);
            var localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            var localVarHeaderParameter = {};
            var localVarQueryParameter = {};
            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }
            if (query !== undefined) {
                localVarQueryParameter['query'] = query;
            }
            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }
            if (sort) {
                localVarQueryParameter['sort'] = sort;
            }
            if (status) {
                localVarQueryParameter['status'] = status;
            }
            if (authorization !== undefined && authorization !== null) {
                localVarHeaderParameter['Authorization'] = String(authorization);
            }
            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary Send admission to resident. In other words set admission status to in-progress.
         * @param {string} admissionId admissionId
         * @param {string} [authorization] Authorization Header
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sendAdmissionToResidentUsingPATCH: function (admissionId, authorization, options) {
            if (options === void 0) { options = {}; }
            // verify required parameter 'admissionId' is not null or undefined
            if (admissionId === null || admissionId === undefined) {
                throw new RequiredError('admissionId', 'Required parameter admissionId was null or undefined when calling sendAdmissionToResidentUsingPATCH.');
            }
            var localVarPath = "/api/v3/admissions/{admissionId}/in-progress"
                .replace("{".concat("admissionId", "}"), encodeURIComponent(String(admissionId)));
            var localVarUrlObj = url.parse(localVarPath, true);
            var localVarRequestOptions = Object.assign({ method: 'PATCH' }, options);
            var localVarHeaderParameter = {};
            var localVarQueryParameter = {};
            if (authorization !== undefined && authorization !== null) {
                localVarHeaderParameter['Authorization'] = String(authorization);
            }
            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary Send notification for given admission
         * @param {AdmissionNotificationModel} body admissionNotificationModel
         * @param {string} admissionId admissionId
         * @param {string} [authorization] Authorization Header
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sendNotificationUsingPOST: function (body, admissionId, authorization, options) {
            if (options === void 0) { options = {}; }
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body', 'Required parameter body was null or undefined when calling sendNotificationUsingPOST.');
            }
            // verify required parameter 'admissionId' is not null or undefined
            if (admissionId === null || admissionId === undefined) {
                throw new RequiredError('admissionId', 'Required parameter admissionId was null or undefined when calling sendNotificationUsingPOST.');
            }
            var localVarPath = "/api/v3/admissions/{admissionId}/notification"
                .replace("{".concat("admissionId", "}"), encodeURIComponent(String(admissionId)));
            var localVarUrlObj = url.parse(localVarPath, true);
            var localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            var localVarHeaderParameter = {};
            var localVarQueryParameter = {};
            if (authorization !== undefined && authorization !== null) {
                localVarHeaderParameter['Authorization'] = String(authorization);
            }
            localVarHeaderParameter['Content-Type'] = 'application/json';
            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            var needsSerialization = ("AdmissionNotificationModel" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body = needsSerialization ? JSON.stringify(body || {}) : (body || "");
            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary Sign an admission
         * @param {string} admissionId admissionId
         * @param {string} [authorization] Authorization Header
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        signAdmissionUsingPATCH: function (admissionId, authorization, options) {
            if (options === void 0) { options = {}; }
            // verify required parameter 'admissionId' is not null or undefined
            if (admissionId === null || admissionId === undefined) {
                throw new RequiredError('admissionId', 'Required parameter admissionId was null or undefined when calling signAdmissionUsingPATCH.');
            }
            var localVarPath = "/api/v3/admissions/{admissionId}/sign"
                .replace("{".concat("admissionId", "}"), encodeURIComponent(String(admissionId)));
            var localVarUrlObj = url.parse(localVarPath, true);
            var localVarRequestOptions = Object.assign({ method: 'PATCH' }, options);
            var localVarHeaderParameter = {};
            var localVarQueryParameter = {};
            if (authorization !== undefined && authorization !== null) {
                localVarHeaderParameter['Authorization'] = String(authorization);
            }
            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary Synchronize with PCC webhook
         * @param {PccSyncDto} body pccSyncDto
         * @param {string} [authorization] Authorization Header
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        syncWithPccUsingPOST: function (body, authorization, options) {
            if (options === void 0) { options = {}; }
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body', 'Required parameter body was null or undefined when calling syncWithPccUsingPOST.');
            }
            var localVarPath = "/api/v3/admissions/sync-with-pcc";
            var localVarUrlObj = url.parse(localVarPath, true);
            var localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            var localVarHeaderParameter = {};
            var localVarQueryParameter = {};
            if (authorization !== undefined && authorization !== null) {
                localVarHeaderParameter['Authorization'] = String(authorization);
            }
            localVarHeaderParameter['Content-Type'] = 'application/json';
            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            var needsSerialization = ("PccSyncDto" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body = needsSerialization ? JSON.stringify(body || {}) : (body || "");
            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary Update admission flags
         * @param {ReadonlyArray<AdmissionFlag>} body admissionFlagsData
         * @param {string} admissionId admissionId
         * @param {string} [authorization] Authorization Header
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateAdmissionFlagsUsingPATCH: function (body, admissionId, authorization, options) {
            if (options === void 0) { options = {}; }
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body', 'Required parameter body was null or undefined when calling updateAdmissionFlagsUsingPATCH.');
            }
            // verify required parameter 'admissionId' is not null or undefined
            if (admissionId === null || admissionId === undefined) {
                throw new RequiredError('admissionId', 'Required parameter admissionId was null or undefined when calling updateAdmissionFlagsUsingPATCH.');
            }
            var localVarPath = "/api/v3/admissions/{admissionId}/flags"
                .replace("{".concat("admissionId", "}"), encodeURIComponent(String(admissionId)));
            var localVarUrlObj = url.parse(localVarPath, true);
            var localVarRequestOptions = Object.assign({ method: 'PATCH' }, options);
            var localVarHeaderParameter = {};
            var localVarQueryParameter = {};
            if (authorization !== undefined && authorization !== null) {
                localVarHeaderParameter['Authorization'] = String(authorization);
            }
            localVarHeaderParameter['Content-Type'] = 'application/json';
            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            var needsSerialization = ("ReadonlyArray&lt;AdmissionFlag&gt;" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body = needsSerialization ? JSON.stringify(body || {}) : (body || "");
            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary Update admission notes
         * @param {ReadonlyArray<AdmissionNote>} body admissionNotesData
         * @param {string} admissionId admissionId
         * @param {string} [authorization] Authorization Header
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateAdmissionNoteUsingPATCH: function (body, admissionId, authorization, options) {
            if (options === void 0) { options = {}; }
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body', 'Required parameter body was null or undefined when calling updateAdmissionNoteUsingPATCH.');
            }
            // verify required parameter 'admissionId' is not null or undefined
            if (admissionId === null || admissionId === undefined) {
                throw new RequiredError('admissionId', 'Required parameter admissionId was null or undefined when calling updateAdmissionNoteUsingPATCH.');
            }
            var localVarPath = "/api/v3/admissions/{admissionId}/notes"
                .replace("{".concat("admissionId", "}"), encodeURIComponent(String(admissionId)));
            var localVarUrlObj = url.parse(localVarPath, true);
            var localVarRequestOptions = Object.assign({ method: 'PATCH' }, options);
            var localVarHeaderParameter = {};
            var localVarQueryParameter = {};
            if (authorization !== undefined && authorization !== null) {
                localVarHeaderParameter['Authorization'] = String(authorization);
            }
            localVarHeaderParameter['Content-Type'] = 'application/json';
            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            var needsSerialization = ("ReadonlyArray&lt;AdmissionNote&gt;" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body = needsSerialization ? JSON.stringify(body || {}) : (body || "");
            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    };
};
exports.AdmissionDocumentControllerApiFetchParamCreator = AdmissionDocumentControllerApiFetchParamCreator;
/**
 * AdmissionDocumentControllerApi - functional programming interface
 * @export
 */
var AdmissionDocumentControllerApiFp = function (configuration) {
    return {
        /**
         *
         * @summary Full administrator view of an admission
         * @param {string} admissionId admissionId
         * @param {string} [authorization] Authorization Header
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminAdmissionUsingGET: function (admissionId, authorization, options) {
            var localVarFetchArgs = (0, exports.AdmissionDocumentControllerApiFetchParamCreator)(configuration).adminAdmissionUsingGET(admissionId, authorization, options);
            return function (fetch, basePath) {
                if (fetch === void 0) { fetch = portableFetch; }
                if (basePath === void 0) { basePath = BASE_PATH; }
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then(function (response) {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json().then(function (data) { return (__assign(__assign({}, response), { data: data })); });
                    }
                    else {
                        throw response;
                    }
                });
            };
        },
        /**
         *
         * @summary Approve an admission
         * @param {Admission} body admission
         * @param {string} admissionId admissionId
         * @param {string} [authorization] Authorization Header
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        approveAdmissionUsingPATCH: function (body, admissionId, authorization, options) {
            var localVarFetchArgs = (0, exports.AdmissionDocumentControllerApiFetchParamCreator)(configuration).approveAdmissionUsingPATCH(body, admissionId, authorization, options);
            return function (fetch, basePath) {
                if (fetch === void 0) { fetch = portableFetch; }
                if (basePath === void 0) { basePath = BASE_PATH; }
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then(function (response) {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json().then(function (data) { return (__assign(__assign({}, response), { data: data })); });
                    }
                    else {
                        throw response;
                    }
                });
            };
        },
        /**
         *
         * @summary Archive an admission
         * @param {string} admissionId admissionId
         * @param {string} [authorization] Authorization Header
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        archiveAdmissionUsingPATCH: function (admissionId, authorization, options) {
            var localVarFetchArgs = (0, exports.AdmissionDocumentControllerApiFetchParamCreator)(configuration).archiveAdmissionUsingPATCH(admissionId, authorization, options);
            return function (fetch, basePath) {
                if (fetch === void 0) { fetch = portableFetch; }
                if (basePath === void 0) { basePath = BASE_PATH; }
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then(function (response) {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json().then(function (data) { return (__assign(__assign({}, response), { data: data })); });
                    }
                    else {
                        throw response;
                    }
                });
            };
        },
        /**
         *
         * @summary Creates admission flag within the admission
         * @param {AdmissionFlag} body admissionFlag
         * @param {string} admissionId admissionId
         * @param {string} [authorization] Authorization Header
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createAdmissionFlagUsingPOST: function (body, admissionId, authorization, options) {
            var localVarFetchArgs = (0, exports.AdmissionDocumentControllerApiFetchParamCreator)(configuration).createAdmissionFlagUsingPOST(body, admissionId, authorization, options);
            return function (fetch, basePath) {
                if (fetch === void 0) { fetch = portableFetch; }
                if (basePath === void 0) { basePath = BASE_PATH; }
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then(function (response) {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json().then(function (data) { return (__assign(__assign({}, response), { data: data })); });
                    }
                    else {
                        throw response;
                    }
                });
            };
        },
        /**
         *
         * @summary Create a new admission
         * @param {Admission} body admissionData
         * @param {string} [authorization] Authorization Header
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createAdmissionUsingPOST: function (body, authorization, options) {
            var localVarFetchArgs = (0, exports.AdmissionDocumentControllerApiFetchParamCreator)(configuration).createAdmissionUsingPOST(body, authorization, options);
            return function (fetch, basePath) {
                if (fetch === void 0) { fetch = portableFetch; }
                if (basePath === void 0) { basePath = BASE_PATH; }
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then(function (response) {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json().then(function (data) { return (__assign(__assign({}, response), { data: data })); });
                    }
                    else {
                        throw response;
                    }
                });
            };
        },
        /**
         *
         * @summary delete admission flag
         * @param {string} admissionId admissionId
         * @param {string} flagId flagId
         * @param {string} [authorization] Authorization Header
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteAdmissionFlagUsingDELETE: function (admissionId, flagId, authorization, options) {
            var localVarFetchArgs = (0, exports.AdmissionDocumentControllerApiFetchParamCreator)(configuration).deleteAdmissionFlagUsingDELETE(admissionId, flagId, authorization, options);
            return function (fetch, basePath) {
                if (fetch === void 0) { fetch = portableFetch; }
                if (basePath === void 0) { basePath = BASE_PATH; }
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then(function (response) {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    }
                    else {
                        throw response;
                    }
                });
            };
        },
        /**
         *
         * @summary Cancel an admission
         * @param {AdmissionCancellationDto} body admissionCancellationDto
         * @param {string} admissionId admissionId
         * @param {string} [authorization] Authorization Header
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteAdmissionUsingPATCH: function (body, admissionId, authorization, options) {
            var localVarFetchArgs = (0, exports.AdmissionDocumentControllerApiFetchParamCreator)(configuration).deleteAdmissionUsingPATCH(body, admissionId, authorization, options);
            return function (fetch, basePath) {
                if (fetch === void 0) { fetch = portableFetch; }
                if (basePath === void 0) { basePath = BASE_PATH; }
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then(function (response) {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json().then(function (data) { return (__assign(__assign({}, response), { data: data })); });
                    }
                    else {
                        throw response;
                    }
                });
            };
        },
        /**
         *
         * @summary Process discharged PCC resident webhook
         * @param {PccDischargeResidentDto} body pccDischargeResidentDto
         * @param {string} [authorization] Authorization Header
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        dischargeResidentPccUsingPOST: function (body, authorization, options) {
            var localVarFetchArgs = (0, exports.AdmissionDocumentControllerApiFetchParamCreator)(configuration).dischargeResidentPccUsingPOST(body, authorization, options);
            return function (fetch, basePath) {
                if (fetch === void 0) { fetch = portableFetch; }
                if (basePath === void 0) { basePath = BASE_PATH; }
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then(function (response) {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    }
                    else {
                        throw response;
                    }
                });
            };
        },
        /**
         *
         * @summary Download generated PDF of given admission based on download or access token
         * @param {string} admissionId admissionId
         * @param {string} groupKey groupKey
         * @param {string} [authorization] Authorization Header
         * @param {string} [accessToken] accessToken
         * @param {string} [token] token
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        downloadPartialPdfFromS3UsingGET: function (admissionId, groupKey, authorization, accessToken, token, options) {
            var localVarFetchArgs = (0, exports.AdmissionDocumentControllerApiFetchParamCreator)(configuration).downloadPartialPdfFromS3UsingGET(admissionId, groupKey, authorization, accessToken, token, options);
            return function (fetch, basePath) {
                if (fetch === void 0) { fetch = portableFetch; }
                if (basePath === void 0) { basePath = BASE_PATH; }
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then(function (response) {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json().then(function (data) { return (__assign(__assign({}, response), { data: data })); });
                    }
                    else {
                        throw response;
                    }
                });
            };
        },
        /**
         *
         * @summary Download generated PDF of given admission based on download or access token
         * @param {string} admissionId admissionId
         * @param {string} [authorization] Authorization Header
         * @param {string} [accessToken] accessToken
         * @param {string} [token] token
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        downloadPdfFromS3UsingGET: function (admissionId, authorization, accessToken, token, options) {
            var localVarFetchArgs = (0, exports.AdmissionDocumentControllerApiFetchParamCreator)(configuration).downloadPdfFromS3UsingGET(admissionId, authorization, accessToken, token, options);
            return function (fetch, basePath) {
                if (fetch === void 0) { fetch = portableFetch; }
                if (basePath === void 0) { basePath = BASE_PATH; }
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then(function (response) {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json().then(function (data) { return (__assign(__assign({}, response), { data: data })); });
                    }
                    else {
                        throw response;
                    }
                });
            };
        },
        /**
         *
         * @summary get all admission flags
         * @param {string} admissionId admissionId
         * @param {string} [authorization] Authorization Header
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAdmissionFlagsUsingGET: function (admissionId, authorization, options) {
            var localVarFetchArgs = (0, exports.AdmissionDocumentControllerApiFetchParamCreator)(configuration).getAdmissionFlagsUsingGET(admissionId, authorization, options);
            return function (fetch, basePath) {
                if (fetch === void 0) { fetch = portableFetch; }
                if (basePath === void 0) { basePath = BASE_PATH; }
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then(function (response) {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json().then(function (data) { return (__assign(__assign({}, response), { data: data })); });
                    }
                    else {
                        throw response;
                    }
                });
            };
        },
        /**
         *
         * @summary Get an admission without postprocessing for internal calls
         * @param {string} admissionId admissionId
         * @param {string} [authorization] Authorization Header
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAdmissionInternalUsingGET: function (admissionId, authorization, options) {
            var localVarFetchArgs = (0, exports.AdmissionDocumentControllerApiFetchParamCreator)(configuration).getAdmissionInternalUsingGET(admissionId, authorization, options);
            return function (fetch, basePath) {
                if (fetch === void 0) { fetch = portableFetch; }
                if (basePath === void 0) { basePath = BASE_PATH; }
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then(function (response) {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json().then(function (data) { return (__assign(__assign({}, response), { data: data })); });
                    }
                    else {
                        throw response;
                    }
                });
            };
        },
        /**
         *
         * @summary get all admission status transitions
         * @param {string} admissionId admissionId
         * @param {string} [authorization] Authorization Header
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAdmissionTransitionsUsingGET: function (admissionId, authorization, options) {
            var localVarFetchArgs = (0, exports.AdmissionDocumentControllerApiFetchParamCreator)(configuration).getAdmissionTransitionsUsingGET(admissionId, authorization, options);
            return function (fetch, basePath) {
                if (fetch === void 0) { fetch = portableFetch; }
                if (basePath === void 0) { basePath = BASE_PATH; }
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then(function (response) {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json().then(function (data) { return (__assign(__assign({}, response), { data: data })); });
                    }
                    else {
                        throw response;
                    }
                });
            };
        },
        /**
         *
         * @summary Get an admission
         * @param {string} admissionId admissionId
         * @param {string} [authorization] Authorization Header
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAdmissionUsingGET: function (admissionId, authorization, options) {
            var localVarFetchArgs = (0, exports.AdmissionDocumentControllerApiFetchParamCreator)(configuration).getAdmissionUsingGET(admissionId, authorization, options);
            return function (fetch, basePath) {
                if (fetch === void 0) { fetch = portableFetch; }
                if (basePath === void 0) { basePath = BASE_PATH; }
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then(function (response) {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json().then(function (data) { return (__assign(__assign({}, response), { data: data })); });
                    }
                    else {
                        throw response;
                    }
                });
            };
        },
        /**
         *
         * @summary Retrieve preview for admission
         * @param {string} admissionId admissionId
         * @param {string} [authorization] Authorization Header
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPreviewUsingGET: function (admissionId, authorization, options) {
            var localVarFetchArgs = (0, exports.AdmissionDocumentControllerApiFetchParamCreator)(configuration).getPreviewUsingGET(admissionId, authorization, options);
            return function (fetch, basePath) {
                if (fetch === void 0) { fetch = portableFetch; }
                if (basePath === void 0) { basePath = BASE_PATH; }
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then(function (response) {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json().then(function (data) { return (__assign(__assign({}, response), { data: data })); });
                    }
                    else {
                        throw response;
                    }
                });
            };
        },
        /**
         *
         * @summary Update admissions
         * @param {AdmissionMigrationDto} body admissionMigrationDto
         * @param {string} [authorization] Authorization Header
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        migrateAdmissionsTemplateUsingPOST: function (body, authorization, options) {
            var localVarFetchArgs = (0, exports.AdmissionDocumentControllerApiFetchParamCreator)(configuration).migrateAdmissionsTemplateUsingPOST(body, authorization, options);
            return function (fetch, basePath) {
                if (fetch === void 0) { fetch = portableFetch; }
                if (basePath === void 0) { basePath = BASE_PATH; }
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then(function (response) {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    }
                    else {
                        throw response;
                    }
                });
            };
        },
        /**
         *
         * @summary Patch admission with PatchAdmissionDto. RESIDENT cannot patch Answers and CompletionPercentage if admission is already SIGNED, but CLERK and ADMINISTRATOR can.
         * @param {PatchAdmissionDto} body patchAdmissionDto
         * @param {string} admissionId admissionId
         * @param {string} [authorization] Authorization Header
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patchAdmissionUsingPATCH: function (body, admissionId, authorization, options) {
            var localVarFetchArgs = (0, exports.AdmissionDocumentControllerApiFetchParamCreator)(configuration).patchAdmissionUsingPATCH(body, admissionId, authorization, options);
            return function (fetch, basePath) {
                if (fetch === void 0) { fetch = portableFetch; }
                if (basePath === void 0) { basePath = BASE_PATH; }
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then(function (response) {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json().then(function (data) { return (__assign(__assign({}, response), { data: data })); });
                    }
                    else {
                        throw response;
                    }
                });
            };
        },
        /**
         *
         * @summary Patch checklist item identified by ID in given admission identified by its ID
         * @param {ChecklistItem} body checklistItem
         * @param {string} admissionId admissionId
         * @param {string} checklistItemId checklistItemId
         * @param {string} [authorization] Authorization Header
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patchChecklistItemUsingPATCH: function (body, admissionId, checklistItemId, authorization, options) {
            var localVarFetchArgs = (0, exports.AdmissionDocumentControllerApiFetchParamCreator)(configuration).patchChecklistItemUsingPATCH(body, admissionId, checklistItemId, authorization, options);
            return function (fetch, basePath) {
                if (fetch === void 0) { fetch = portableFetch; }
                if (basePath === void 0) { basePath = BASE_PATH; }
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then(function (response) {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json().then(function (data) { return (__assign(__assign({}, response), { data: data })); });
                    }
                    else {
                        throw response;
                    }
                });
            };
        },
        /**
         *
         * @summary Process admission with pending PCC account
         * @param {AdmissionWithPendingPatientDto} body admissionWithPendingPatientDto
         * @param {string} [authorization] Authorization Header
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        processAdmissionWithPendingPccAccountUsingPOST: function (body, authorization, options) {
            var localVarFetchArgs = (0, exports.AdmissionDocumentControllerApiFetchParamCreator)(configuration).processAdmissionWithPendingPccAccountUsingPOST(body, authorization, options);
            return function (fetch, basePath) {
                if (fetch === void 0) { fetch = portableFetch; }
                if (basePath === void 0) { basePath = BASE_PATH; }
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then(function (response) {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    }
                    else {
                        throw response;
                    }
                });
            };
        },
        /**
         *
         * @summary Restore cancelled admission
         * @param {string} admissionId admissionId
         * @param {string} [authorization] Authorization Header
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        restoreAdmissionUsingPATCH: function (admissionId, authorization, options) {
            var localVarFetchArgs = (0, exports.AdmissionDocumentControllerApiFetchParamCreator)(configuration).restoreAdmissionUsingPATCH(admissionId, authorization, options);
            return function (fetch, basePath) {
                if (fetch === void 0) { fetch = portableFetch; }
                if (basePath === void 0) { basePath = BASE_PATH; }
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then(function (response) {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json().then(function (data) { return (__assign(__assign({}, response), { data: data })); });
                    }
                    else {
                        throw response;
                    }
                });
            };
        },
        /**
         *
         * @summary Reupload the admission PDF contract files
         * @param {string} admissionId admissionId
         * @param {string} [authorization] Authorization Header
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        reuploadContractUsingGET: function (admissionId, authorization, options) {
            var localVarFetchArgs = (0, exports.AdmissionDocumentControllerApiFetchParamCreator)(configuration).reuploadContractUsingGET(admissionId, authorization, options);
            return function (fetch, basePath) {
                if (fetch === void 0) { fetch = portableFetch; }
                if (basePath === void 0) { basePath = BASE_PATH; }
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then(function (response) {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    }
                    else {
                        throw response;
                    }
                });
            };
        },
        /**
         *
         * @summary Patch admission with PatchAdmissionDto. RESIDENT can patch only Answers which are on slides marked as afterAdmission if admission is already SIGNED.
         * @param {PatchAdmissionDto} body patchAdmissionDto
         * @param {string} admissionId admissionId
         * @param {string} [authorization] Authorization Header
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        saveAfterAdmissionAnswersUsingPATCH: function (body, admissionId, authorization, options) {
            var localVarFetchArgs = (0, exports.AdmissionDocumentControllerApiFetchParamCreator)(configuration).saveAfterAdmissionAnswersUsingPATCH(body, admissionId, authorization, options);
            return function (fetch, basePath) {
                if (fetch === void 0) { fetch = portableFetch; }
                if (basePath === void 0) { basePath = BASE_PATH; }
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then(function (response) {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json().then(function (data) { return (__assign(__assign({}, response), { data: data })); });
                    }
                    else {
                        throw response;
                    }
                });
            };
        },
        /**
         *
         * @summary Search admissions. Returns Page<AdmissionPreview>
         * @param {ParametersDto} body body
         * @param {string} [authorization] Authorization Header
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchAdmissionUsingPOST: function (body, authorization, options) {
            var localVarFetchArgs = (0, exports.AdmissionDocumentControllerApiFetchParamCreator)(configuration).searchAdmissionUsingPOST(body, authorization, options);
            return function (fetch, basePath) {
                if (fetch === void 0) { fetch = portableFetch; }
                if (basePath === void 0) { basePath = BASE_PATH; }
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then(function (response) {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json().then(function (data) { return (__assign(__assign({}, response), { data: data })); });
                    }
                    else {
                        throw response;
                    }
                });
            };
        },
        /**
         *
         * @summary Search admissions by query resident name or code or resident code. Returns Page<AdmissionPreview>
         * @param {string} query query
         * @param {string} [authorization] Authorization Header
         * @param {number} [page]
         * @param {number} [size]
         * @param {ReadonlyArray<string>} [sort]
         * @param {ReadonlyArray<string>} [status] status
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchAdmissionsByQueryUsingGET: function (query, authorization, page, size, sort, status, options) {
            var localVarFetchArgs = (0, exports.AdmissionDocumentControllerApiFetchParamCreator)(configuration).searchAdmissionsByQueryUsingGET(query, authorization, page, size, sort, status, options);
            return function (fetch, basePath) {
                if (fetch === void 0) { fetch = portableFetch; }
                if (basePath === void 0) { basePath = BASE_PATH; }
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then(function (response) {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json().then(function (data) { return (__assign(__assign({}, response), { data: data })); });
                    }
                    else {
                        throw response;
                    }
                });
            };
        },
        /**
         *
         * @summary Send admission to resident. In other words set admission status to in-progress.
         * @param {string} admissionId admissionId
         * @param {string} [authorization] Authorization Header
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sendAdmissionToResidentUsingPATCH: function (admissionId, authorization, options) {
            var localVarFetchArgs = (0, exports.AdmissionDocumentControllerApiFetchParamCreator)(configuration).sendAdmissionToResidentUsingPATCH(admissionId, authorization, options);
            return function (fetch, basePath) {
                if (fetch === void 0) { fetch = portableFetch; }
                if (basePath === void 0) { basePath = BASE_PATH; }
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then(function (response) {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json().then(function (data) { return (__assign(__assign({}, response), { data: data })); });
                    }
                    else {
                        throw response;
                    }
                });
            };
        },
        /**
         *
         * @summary Send notification for given admission
         * @param {AdmissionNotificationModel} body admissionNotificationModel
         * @param {string} admissionId admissionId
         * @param {string} [authorization] Authorization Header
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sendNotificationUsingPOST: function (body, admissionId, authorization, options) {
            var localVarFetchArgs = (0, exports.AdmissionDocumentControllerApiFetchParamCreator)(configuration).sendNotificationUsingPOST(body, admissionId, authorization, options);
            return function (fetch, basePath) {
                if (fetch === void 0) { fetch = portableFetch; }
                if (basePath === void 0) { basePath = BASE_PATH; }
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then(function (response) {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    }
                    else {
                        throw response;
                    }
                });
            };
        },
        /**
         *
         * @summary Sign an admission
         * @param {string} admissionId admissionId
         * @param {string} [authorization] Authorization Header
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        signAdmissionUsingPATCH: function (admissionId, authorization, options) {
            var localVarFetchArgs = (0, exports.AdmissionDocumentControllerApiFetchParamCreator)(configuration).signAdmissionUsingPATCH(admissionId, authorization, options);
            return function (fetch, basePath) {
                if (fetch === void 0) { fetch = portableFetch; }
                if (basePath === void 0) { basePath = BASE_PATH; }
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then(function (response) {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json().then(function (data) { return (__assign(__assign({}, response), { data: data })); });
                    }
                    else {
                        throw response;
                    }
                });
            };
        },
        /**
         *
         * @summary Synchronize with PCC webhook
         * @param {PccSyncDto} body pccSyncDto
         * @param {string} [authorization] Authorization Header
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        syncWithPccUsingPOST: function (body, authorization, options) {
            var localVarFetchArgs = (0, exports.AdmissionDocumentControllerApiFetchParamCreator)(configuration).syncWithPccUsingPOST(body, authorization, options);
            return function (fetch, basePath) {
                if (fetch === void 0) { fetch = portableFetch; }
                if (basePath === void 0) { basePath = BASE_PATH; }
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then(function (response) {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    }
                    else {
                        throw response;
                    }
                });
            };
        },
        /**
         *
         * @summary Update admission flags
         * @param {ReadonlyArray<AdmissionFlag>} body admissionFlagsData
         * @param {string} admissionId admissionId
         * @param {string} [authorization] Authorization Header
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateAdmissionFlagsUsingPATCH: function (body, admissionId, authorization, options) {
            var localVarFetchArgs = (0, exports.AdmissionDocumentControllerApiFetchParamCreator)(configuration).updateAdmissionFlagsUsingPATCH(body, admissionId, authorization, options);
            return function (fetch, basePath) {
                if (fetch === void 0) { fetch = portableFetch; }
                if (basePath === void 0) { basePath = BASE_PATH; }
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then(function (response) {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json().then(function (data) { return (__assign(__assign({}, response), { data: data })); });
                    }
                    else {
                        throw response;
                    }
                });
            };
        },
        /**
         *
         * @summary Update admission notes
         * @param {ReadonlyArray<AdmissionNote>} body admissionNotesData
         * @param {string} admissionId admissionId
         * @param {string} [authorization] Authorization Header
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateAdmissionNoteUsingPATCH: function (body, admissionId, authorization, options) {
            var localVarFetchArgs = (0, exports.AdmissionDocumentControllerApiFetchParamCreator)(configuration).updateAdmissionNoteUsingPATCH(body, admissionId, authorization, options);
            return function (fetch, basePath) {
                if (fetch === void 0) { fetch = portableFetch; }
                if (basePath === void 0) { basePath = BASE_PATH; }
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then(function (response) {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json().then(function (data) { return (__assign(__assign({}, response), { data: data })); });
                    }
                    else {
                        throw response;
                    }
                });
            };
        },
    };
};
exports.AdmissionDocumentControllerApiFp = AdmissionDocumentControllerApiFp;
/**
 * AdmissionDocumentControllerApi - factory interface
 * @export
 */
var AdmissionDocumentControllerApiFactory = function (configuration, fetch, basePath) {
    return {
        /**
         *
         * @summary Full administrator view of an admission
         * @param {string} admissionId admissionId
         * @param {string} [authorization] Authorization Header
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminAdmissionUsingGET: function (admissionId, authorization, options) {
            return (0, exports.AdmissionDocumentControllerApiFp)(configuration).adminAdmissionUsingGET(admissionId, authorization, options)(fetch, basePath);
        },
        /**
         *
         * @summary Approve an admission
         * @param {Admission} body admission
         * @param {string} admissionId admissionId
         * @param {string} [authorization] Authorization Header
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        approveAdmissionUsingPATCH: function (body, admissionId, authorization, options) {
            return (0, exports.AdmissionDocumentControllerApiFp)(configuration).approveAdmissionUsingPATCH(body, admissionId, authorization, options)(fetch, basePath);
        },
        /**
         *
         * @summary Archive an admission
         * @param {string} admissionId admissionId
         * @param {string} [authorization] Authorization Header
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        archiveAdmissionUsingPATCH: function (admissionId, authorization, options) {
            return (0, exports.AdmissionDocumentControllerApiFp)(configuration).archiveAdmissionUsingPATCH(admissionId, authorization, options)(fetch, basePath);
        },
        /**
         *
         * @summary Creates admission flag within the admission
         * @param {AdmissionFlag} body admissionFlag
         * @param {string} admissionId admissionId
         * @param {string} [authorization] Authorization Header
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createAdmissionFlagUsingPOST: function (body, admissionId, authorization, options) {
            return (0, exports.AdmissionDocumentControllerApiFp)(configuration).createAdmissionFlagUsingPOST(body, admissionId, authorization, options)(fetch, basePath);
        },
        /**
         *
         * @summary Create a new admission
         * @param {Admission} body admissionData
         * @param {string} [authorization] Authorization Header
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createAdmissionUsingPOST: function (body, authorization, options) {
            return (0, exports.AdmissionDocumentControllerApiFp)(configuration).createAdmissionUsingPOST(body, authorization, options)(fetch, basePath);
        },
        /**
         *
         * @summary delete admission flag
         * @param {string} admissionId admissionId
         * @param {string} flagId flagId
         * @param {string} [authorization] Authorization Header
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteAdmissionFlagUsingDELETE: function (admissionId, flagId, authorization, options) {
            return (0, exports.AdmissionDocumentControllerApiFp)(configuration).deleteAdmissionFlagUsingDELETE(admissionId, flagId, authorization, options)(fetch, basePath);
        },
        /**
         *
         * @summary Cancel an admission
         * @param {AdmissionCancellationDto} body admissionCancellationDto
         * @param {string} admissionId admissionId
         * @param {string} [authorization] Authorization Header
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteAdmissionUsingPATCH: function (body, admissionId, authorization, options) {
            return (0, exports.AdmissionDocumentControllerApiFp)(configuration).deleteAdmissionUsingPATCH(body, admissionId, authorization, options)(fetch, basePath);
        },
        /**
         *
         * @summary Process discharged PCC resident webhook
         * @param {PccDischargeResidentDto} body pccDischargeResidentDto
         * @param {string} [authorization] Authorization Header
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        dischargeResidentPccUsingPOST: function (body, authorization, options) {
            return (0, exports.AdmissionDocumentControllerApiFp)(configuration).dischargeResidentPccUsingPOST(body, authorization, options)(fetch, basePath);
        },
        /**
         *
         * @summary Download generated PDF of given admission based on download or access token
         * @param {string} admissionId admissionId
         * @param {string} groupKey groupKey
         * @param {string} [authorization] Authorization Header
         * @param {string} [accessToken] accessToken
         * @param {string} [token] token
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        downloadPartialPdfFromS3UsingGET: function (admissionId, groupKey, authorization, accessToken, token, options) {
            return (0, exports.AdmissionDocumentControllerApiFp)(configuration).downloadPartialPdfFromS3UsingGET(admissionId, groupKey, authorization, accessToken, token, options)(fetch, basePath);
        },
        /**
         *
         * @summary Download generated PDF of given admission based on download or access token
         * @param {string} admissionId admissionId
         * @param {string} [authorization] Authorization Header
         * @param {string} [accessToken] accessToken
         * @param {string} [token] token
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        downloadPdfFromS3UsingGET: function (admissionId, authorization, accessToken, token, options) {
            return (0, exports.AdmissionDocumentControllerApiFp)(configuration).downloadPdfFromS3UsingGET(admissionId, authorization, accessToken, token, options)(fetch, basePath);
        },
        /**
         *
         * @summary get all admission flags
         * @param {string} admissionId admissionId
         * @param {string} [authorization] Authorization Header
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAdmissionFlagsUsingGET: function (admissionId, authorization, options) {
            return (0, exports.AdmissionDocumentControllerApiFp)(configuration).getAdmissionFlagsUsingGET(admissionId, authorization, options)(fetch, basePath);
        },
        /**
         *
         * @summary Get an admission without postprocessing for internal calls
         * @param {string} admissionId admissionId
         * @param {string} [authorization] Authorization Header
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAdmissionInternalUsingGET: function (admissionId, authorization, options) {
            return (0, exports.AdmissionDocumentControllerApiFp)(configuration).getAdmissionInternalUsingGET(admissionId, authorization, options)(fetch, basePath);
        },
        /**
         *
         * @summary get all admission status transitions
         * @param {string} admissionId admissionId
         * @param {string} [authorization] Authorization Header
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAdmissionTransitionsUsingGET: function (admissionId, authorization, options) {
            return (0, exports.AdmissionDocumentControllerApiFp)(configuration).getAdmissionTransitionsUsingGET(admissionId, authorization, options)(fetch, basePath);
        },
        /**
         *
         * @summary Get an admission
         * @param {string} admissionId admissionId
         * @param {string} [authorization] Authorization Header
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAdmissionUsingGET: function (admissionId, authorization, options) {
            return (0, exports.AdmissionDocumentControllerApiFp)(configuration).getAdmissionUsingGET(admissionId, authorization, options)(fetch, basePath);
        },
        /**
         *
         * @summary Retrieve preview for admission
         * @param {string} admissionId admissionId
         * @param {string} [authorization] Authorization Header
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPreviewUsingGET: function (admissionId, authorization, options) {
            return (0, exports.AdmissionDocumentControllerApiFp)(configuration).getPreviewUsingGET(admissionId, authorization, options)(fetch, basePath);
        },
        /**
         *
         * @summary Update admissions
         * @param {AdmissionMigrationDto} body admissionMigrationDto
         * @param {string} [authorization] Authorization Header
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        migrateAdmissionsTemplateUsingPOST: function (body, authorization, options) {
            return (0, exports.AdmissionDocumentControllerApiFp)(configuration).migrateAdmissionsTemplateUsingPOST(body, authorization, options)(fetch, basePath);
        },
        /**
         *
         * @summary Patch admission with PatchAdmissionDto. RESIDENT cannot patch Answers and CompletionPercentage if admission is already SIGNED, but CLERK and ADMINISTRATOR can.
         * @param {PatchAdmissionDto} body patchAdmissionDto
         * @param {string} admissionId admissionId
         * @param {string} [authorization] Authorization Header
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patchAdmissionUsingPATCH: function (body, admissionId, authorization, options) {
            return (0, exports.AdmissionDocumentControllerApiFp)(configuration).patchAdmissionUsingPATCH(body, admissionId, authorization, options)(fetch, basePath);
        },
        /**
         *
         * @summary Patch checklist item identified by ID in given admission identified by its ID
         * @param {ChecklistItem} body checklistItem
         * @param {string} admissionId admissionId
         * @param {string} checklistItemId checklistItemId
         * @param {string} [authorization] Authorization Header
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patchChecklistItemUsingPATCH: function (body, admissionId, checklistItemId, authorization, options) {
            return (0, exports.AdmissionDocumentControllerApiFp)(configuration).patchChecklistItemUsingPATCH(body, admissionId, checklistItemId, authorization, options)(fetch, basePath);
        },
        /**
         *
         * @summary Process admission with pending PCC account
         * @param {AdmissionWithPendingPatientDto} body admissionWithPendingPatientDto
         * @param {string} [authorization] Authorization Header
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        processAdmissionWithPendingPccAccountUsingPOST: function (body, authorization, options) {
            return (0, exports.AdmissionDocumentControllerApiFp)(configuration).processAdmissionWithPendingPccAccountUsingPOST(body, authorization, options)(fetch, basePath);
        },
        /**
         *
         * @summary Restore cancelled admission
         * @param {string} admissionId admissionId
         * @param {string} [authorization] Authorization Header
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        restoreAdmissionUsingPATCH: function (admissionId, authorization, options) {
            return (0, exports.AdmissionDocumentControllerApiFp)(configuration).restoreAdmissionUsingPATCH(admissionId, authorization, options)(fetch, basePath);
        },
        /**
         *
         * @summary Reupload the admission PDF contract files
         * @param {string} admissionId admissionId
         * @param {string} [authorization] Authorization Header
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        reuploadContractUsingGET: function (admissionId, authorization, options) {
            return (0, exports.AdmissionDocumentControllerApiFp)(configuration).reuploadContractUsingGET(admissionId, authorization, options)(fetch, basePath);
        },
        /**
         *
         * @summary Patch admission with PatchAdmissionDto. RESIDENT can patch only Answers which are on slides marked as afterAdmission if admission is already SIGNED.
         * @param {PatchAdmissionDto} body patchAdmissionDto
         * @param {string} admissionId admissionId
         * @param {string} [authorization] Authorization Header
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        saveAfterAdmissionAnswersUsingPATCH: function (body, admissionId, authorization, options) {
            return (0, exports.AdmissionDocumentControllerApiFp)(configuration).saveAfterAdmissionAnswersUsingPATCH(body, admissionId, authorization, options)(fetch, basePath);
        },
        /**
         *
         * @summary Search admissions. Returns Page<AdmissionPreview>
         * @param {ParametersDto} body body
         * @param {string} [authorization] Authorization Header
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchAdmissionUsingPOST: function (body, authorization, options) {
            return (0, exports.AdmissionDocumentControllerApiFp)(configuration).searchAdmissionUsingPOST(body, authorization, options)(fetch, basePath);
        },
        /**
         *
         * @summary Search admissions by query resident name or code or resident code. Returns Page<AdmissionPreview>
         * @param {string} query query
         * @param {string} [authorization] Authorization Header
         * @param {number} [page]
         * @param {number} [size]
         * @param {ReadonlyArray<string>} [sort]
         * @param {ReadonlyArray<string>} [status] status
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchAdmissionsByQueryUsingGET: function (query, authorization, page, size, sort, status, options) {
            return (0, exports.AdmissionDocumentControllerApiFp)(configuration).searchAdmissionsByQueryUsingGET(query, authorization, page, size, sort, status, options)(fetch, basePath);
        },
        /**
         *
         * @summary Send admission to resident. In other words set admission status to in-progress.
         * @param {string} admissionId admissionId
         * @param {string} [authorization] Authorization Header
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sendAdmissionToResidentUsingPATCH: function (admissionId, authorization, options) {
            return (0, exports.AdmissionDocumentControllerApiFp)(configuration).sendAdmissionToResidentUsingPATCH(admissionId, authorization, options)(fetch, basePath);
        },
        /**
         *
         * @summary Send notification for given admission
         * @param {AdmissionNotificationModel} body admissionNotificationModel
         * @param {string} admissionId admissionId
         * @param {string} [authorization] Authorization Header
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sendNotificationUsingPOST: function (body, admissionId, authorization, options) {
            return (0, exports.AdmissionDocumentControllerApiFp)(configuration).sendNotificationUsingPOST(body, admissionId, authorization, options)(fetch, basePath);
        },
        /**
         *
         * @summary Sign an admission
         * @param {string} admissionId admissionId
         * @param {string} [authorization] Authorization Header
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        signAdmissionUsingPATCH: function (admissionId, authorization, options) {
            return (0, exports.AdmissionDocumentControllerApiFp)(configuration).signAdmissionUsingPATCH(admissionId, authorization, options)(fetch, basePath);
        },
        /**
         *
         * @summary Synchronize with PCC webhook
         * @param {PccSyncDto} body pccSyncDto
         * @param {string} [authorization] Authorization Header
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        syncWithPccUsingPOST: function (body, authorization, options) {
            return (0, exports.AdmissionDocumentControllerApiFp)(configuration).syncWithPccUsingPOST(body, authorization, options)(fetch, basePath);
        },
        /**
         *
         * @summary Update admission flags
         * @param {ReadonlyArray<AdmissionFlag>} body admissionFlagsData
         * @param {string} admissionId admissionId
         * @param {string} [authorization] Authorization Header
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateAdmissionFlagsUsingPATCH: function (body, admissionId, authorization, options) {
            return (0, exports.AdmissionDocumentControllerApiFp)(configuration).updateAdmissionFlagsUsingPATCH(body, admissionId, authorization, options)(fetch, basePath);
        },
        /**
         *
         * @summary Update admission notes
         * @param {ReadonlyArray<AdmissionNote>} body admissionNotesData
         * @param {string} admissionId admissionId
         * @param {string} [authorization] Authorization Header
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateAdmissionNoteUsingPATCH: function (body, admissionId, authorization, options) {
            return (0, exports.AdmissionDocumentControllerApiFp)(configuration).updateAdmissionNoteUsingPATCH(body, admissionId, authorization, options)(fetch, basePath);
        },
    };
};
exports.AdmissionDocumentControllerApiFactory = AdmissionDocumentControllerApiFactory;
/**
 * AdmissionDocumentControllerApi - object-oriented interface
 * @export
 * @class AdmissionDocumentControllerApi
 * @extends {BaseAPI}
 */
var AdmissionDocumentControllerApi = /** @class */ (function (_super) {
    __extends(AdmissionDocumentControllerApi, _super);
    function AdmissionDocumentControllerApi() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    /**
     *
     * @summary Full administrator view of an admission
     * @param {string} admissionId admissionId
     * @param {string} [authorization] Authorization Header
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdmissionDocumentControllerApi
     */
    AdmissionDocumentControllerApi.prototype.adminAdmissionUsingGET = function (admissionId, authorization, options) {
        return (0, exports.AdmissionDocumentControllerApiFp)(this.configuration).adminAdmissionUsingGET(admissionId, authorization, options)(this.fetch, this.basePath);
    };
    /**
     *
     * @summary Approve an admission
     * @param {Admission} body admission
     * @param {string} admissionId admissionId
     * @param {string} [authorization] Authorization Header
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdmissionDocumentControllerApi
     */
    AdmissionDocumentControllerApi.prototype.approveAdmissionUsingPATCH = function (body, admissionId, authorization, options) {
        return (0, exports.AdmissionDocumentControllerApiFp)(this.configuration).approveAdmissionUsingPATCH(body, admissionId, authorization, options)(this.fetch, this.basePath);
    };
    /**
     *
     * @summary Archive an admission
     * @param {string} admissionId admissionId
     * @param {string} [authorization] Authorization Header
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdmissionDocumentControllerApi
     */
    AdmissionDocumentControllerApi.prototype.archiveAdmissionUsingPATCH = function (admissionId, authorization, options) {
        return (0, exports.AdmissionDocumentControllerApiFp)(this.configuration).archiveAdmissionUsingPATCH(admissionId, authorization, options)(this.fetch, this.basePath);
    };
    /**
     *
     * @summary Creates admission flag within the admission
     * @param {AdmissionFlag} body admissionFlag
     * @param {string} admissionId admissionId
     * @param {string} [authorization] Authorization Header
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdmissionDocumentControllerApi
     */
    AdmissionDocumentControllerApi.prototype.createAdmissionFlagUsingPOST = function (body, admissionId, authorization, options) {
        return (0, exports.AdmissionDocumentControllerApiFp)(this.configuration).createAdmissionFlagUsingPOST(body, admissionId, authorization, options)(this.fetch, this.basePath);
    };
    /**
     *
     * @summary Create a new admission
     * @param {Admission} body admissionData
     * @param {string} [authorization] Authorization Header
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdmissionDocumentControllerApi
     */
    AdmissionDocumentControllerApi.prototype.createAdmissionUsingPOST = function (body, authorization, options) {
        return (0, exports.AdmissionDocumentControllerApiFp)(this.configuration).createAdmissionUsingPOST(body, authorization, options)(this.fetch, this.basePath);
    };
    /**
     *
     * @summary delete admission flag
     * @param {string} admissionId admissionId
     * @param {string} flagId flagId
     * @param {string} [authorization] Authorization Header
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdmissionDocumentControllerApi
     */
    AdmissionDocumentControllerApi.prototype.deleteAdmissionFlagUsingDELETE = function (admissionId, flagId, authorization, options) {
        return (0, exports.AdmissionDocumentControllerApiFp)(this.configuration).deleteAdmissionFlagUsingDELETE(admissionId, flagId, authorization, options)(this.fetch, this.basePath);
    };
    /**
     *
     * @summary Cancel an admission
     * @param {AdmissionCancellationDto} body admissionCancellationDto
     * @param {string} admissionId admissionId
     * @param {string} [authorization] Authorization Header
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdmissionDocumentControllerApi
     */
    AdmissionDocumentControllerApi.prototype.deleteAdmissionUsingPATCH = function (body, admissionId, authorization, options) {
        return (0, exports.AdmissionDocumentControllerApiFp)(this.configuration).deleteAdmissionUsingPATCH(body, admissionId, authorization, options)(this.fetch, this.basePath);
    };
    /**
     *
     * @summary Process discharged PCC resident webhook
     * @param {PccDischargeResidentDto} body pccDischargeResidentDto
     * @param {string} [authorization] Authorization Header
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdmissionDocumentControllerApi
     */
    AdmissionDocumentControllerApi.prototype.dischargeResidentPccUsingPOST = function (body, authorization, options) {
        return (0, exports.AdmissionDocumentControllerApiFp)(this.configuration).dischargeResidentPccUsingPOST(body, authorization, options)(this.fetch, this.basePath);
    };
    /**
     *
     * @summary Download generated PDF of given admission based on download or access token
     * @param {string} admissionId admissionId
     * @param {string} groupKey groupKey
     * @param {string} [authorization] Authorization Header
     * @param {string} [accessToken] accessToken
     * @param {string} [token] token
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdmissionDocumentControllerApi
     */
    AdmissionDocumentControllerApi.prototype.downloadPartialPdfFromS3UsingGET = function (admissionId, groupKey, authorization, accessToken, token, options) {
        return (0, exports.AdmissionDocumentControllerApiFp)(this.configuration).downloadPartialPdfFromS3UsingGET(admissionId, groupKey, authorization, accessToken, token, options)(this.fetch, this.basePath);
    };
    /**
     *
     * @summary Download generated PDF of given admission based on download or access token
     * @param {string} admissionId admissionId
     * @param {string} [authorization] Authorization Header
     * @param {string} [accessToken] accessToken
     * @param {string} [token] token
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdmissionDocumentControllerApi
     */
    AdmissionDocumentControllerApi.prototype.downloadPdfFromS3UsingGET = function (admissionId, authorization, accessToken, token, options) {
        return (0, exports.AdmissionDocumentControllerApiFp)(this.configuration).downloadPdfFromS3UsingGET(admissionId, authorization, accessToken, token, options)(this.fetch, this.basePath);
    };
    /**
     *
     * @summary get all admission flags
     * @param {string} admissionId admissionId
     * @param {string} [authorization] Authorization Header
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdmissionDocumentControllerApi
     */
    AdmissionDocumentControllerApi.prototype.getAdmissionFlagsUsingGET = function (admissionId, authorization, options) {
        return (0, exports.AdmissionDocumentControllerApiFp)(this.configuration).getAdmissionFlagsUsingGET(admissionId, authorization, options)(this.fetch, this.basePath);
    };
    /**
     *
     * @summary Get an admission without postprocessing for internal calls
     * @param {string} admissionId admissionId
     * @param {string} [authorization] Authorization Header
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdmissionDocumentControllerApi
     */
    AdmissionDocumentControllerApi.prototype.getAdmissionInternalUsingGET = function (admissionId, authorization, options) {
        return (0, exports.AdmissionDocumentControllerApiFp)(this.configuration).getAdmissionInternalUsingGET(admissionId, authorization, options)(this.fetch, this.basePath);
    };
    /**
     *
     * @summary get all admission status transitions
     * @param {string} admissionId admissionId
     * @param {string} [authorization] Authorization Header
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdmissionDocumentControllerApi
     */
    AdmissionDocumentControllerApi.prototype.getAdmissionTransitionsUsingGET = function (admissionId, authorization, options) {
        return (0, exports.AdmissionDocumentControllerApiFp)(this.configuration).getAdmissionTransitionsUsingGET(admissionId, authorization, options)(this.fetch, this.basePath);
    };
    /**
     *
     * @summary Get an admission
     * @param {string} admissionId admissionId
     * @param {string} [authorization] Authorization Header
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdmissionDocumentControllerApi
     */
    AdmissionDocumentControllerApi.prototype.getAdmissionUsingGET = function (admissionId, authorization, options) {
        return (0, exports.AdmissionDocumentControllerApiFp)(this.configuration).getAdmissionUsingGET(admissionId, authorization, options)(this.fetch, this.basePath);
    };
    /**
     *
     * @summary Retrieve preview for admission
     * @param {string} admissionId admissionId
     * @param {string} [authorization] Authorization Header
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdmissionDocumentControllerApi
     */
    AdmissionDocumentControllerApi.prototype.getPreviewUsingGET = function (admissionId, authorization, options) {
        return (0, exports.AdmissionDocumentControllerApiFp)(this.configuration).getPreviewUsingGET(admissionId, authorization, options)(this.fetch, this.basePath);
    };
    /**
     *
     * @summary Update admissions
     * @param {AdmissionMigrationDto} body admissionMigrationDto
     * @param {string} [authorization] Authorization Header
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdmissionDocumentControllerApi
     */
    AdmissionDocumentControllerApi.prototype.migrateAdmissionsTemplateUsingPOST = function (body, authorization, options) {
        return (0, exports.AdmissionDocumentControllerApiFp)(this.configuration).migrateAdmissionsTemplateUsingPOST(body, authorization, options)(this.fetch, this.basePath);
    };
    /**
     *
     * @summary Patch admission with PatchAdmissionDto. RESIDENT cannot patch Answers and CompletionPercentage if admission is already SIGNED, but CLERK and ADMINISTRATOR can.
     * @param {PatchAdmissionDto} body patchAdmissionDto
     * @param {string} admissionId admissionId
     * @param {string} [authorization] Authorization Header
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdmissionDocumentControllerApi
     */
    AdmissionDocumentControllerApi.prototype.patchAdmissionUsingPATCH = function (body, admissionId, authorization, options) {
        return (0, exports.AdmissionDocumentControllerApiFp)(this.configuration).patchAdmissionUsingPATCH(body, admissionId, authorization, options)(this.fetch, this.basePath);
    };
    /**
     *
     * @summary Patch checklist item identified by ID in given admission identified by its ID
     * @param {ChecklistItem} body checklistItem
     * @param {string} admissionId admissionId
     * @param {string} checklistItemId checklistItemId
     * @param {string} [authorization] Authorization Header
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdmissionDocumentControllerApi
     */
    AdmissionDocumentControllerApi.prototype.patchChecklistItemUsingPATCH = function (body, admissionId, checklistItemId, authorization, options) {
        return (0, exports.AdmissionDocumentControllerApiFp)(this.configuration).patchChecklistItemUsingPATCH(body, admissionId, checklistItemId, authorization, options)(this.fetch, this.basePath);
    };
    /**
     *
     * @summary Process admission with pending PCC account
     * @param {AdmissionWithPendingPatientDto} body admissionWithPendingPatientDto
     * @param {string} [authorization] Authorization Header
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdmissionDocumentControllerApi
     */
    AdmissionDocumentControllerApi.prototype.processAdmissionWithPendingPccAccountUsingPOST = function (body, authorization, options) {
        return (0, exports.AdmissionDocumentControllerApiFp)(this.configuration).processAdmissionWithPendingPccAccountUsingPOST(body, authorization, options)(this.fetch, this.basePath);
    };
    /**
     *
     * @summary Restore cancelled admission
     * @param {string} admissionId admissionId
     * @param {string} [authorization] Authorization Header
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdmissionDocumentControllerApi
     */
    AdmissionDocumentControllerApi.prototype.restoreAdmissionUsingPATCH = function (admissionId, authorization, options) {
        return (0, exports.AdmissionDocumentControllerApiFp)(this.configuration).restoreAdmissionUsingPATCH(admissionId, authorization, options)(this.fetch, this.basePath);
    };
    /**
     *
     * @summary Reupload the admission PDF contract files
     * @param {string} admissionId admissionId
     * @param {string} [authorization] Authorization Header
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdmissionDocumentControllerApi
     */
    AdmissionDocumentControllerApi.prototype.reuploadContractUsingGET = function (admissionId, authorization, options) {
        return (0, exports.AdmissionDocumentControllerApiFp)(this.configuration).reuploadContractUsingGET(admissionId, authorization, options)(this.fetch, this.basePath);
    };
    /**
     *
     * @summary Patch admission with PatchAdmissionDto. RESIDENT can patch only Answers which are on slides marked as afterAdmission if admission is already SIGNED.
     * @param {PatchAdmissionDto} body patchAdmissionDto
     * @param {string} admissionId admissionId
     * @param {string} [authorization] Authorization Header
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdmissionDocumentControllerApi
     */
    AdmissionDocumentControllerApi.prototype.saveAfterAdmissionAnswersUsingPATCH = function (body, admissionId, authorization, options) {
        return (0, exports.AdmissionDocumentControllerApiFp)(this.configuration).saveAfterAdmissionAnswersUsingPATCH(body, admissionId, authorization, options)(this.fetch, this.basePath);
    };
    /**
     *
     * @summary Search admissions. Returns Page<AdmissionPreview>
     * @param {ParametersDto} body body
     * @param {string} [authorization] Authorization Header
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdmissionDocumentControllerApi
     */
    AdmissionDocumentControllerApi.prototype.searchAdmissionUsingPOST = function (body, authorization, options) {
        return (0, exports.AdmissionDocumentControllerApiFp)(this.configuration).searchAdmissionUsingPOST(body, authorization, options)(this.fetch, this.basePath);
    };
    /**
     *
     * @summary Search admissions by query resident name or code or resident code. Returns Page<AdmissionPreview>
     * @param {string} query query
     * @param {string} [authorization] Authorization Header
     * @param {number} [page]
     * @param {number} [size]
     * @param {ReadonlyArray<string>} [sort]
     * @param {ReadonlyArray<string>} [status] status
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdmissionDocumentControllerApi
     */
    AdmissionDocumentControllerApi.prototype.searchAdmissionsByQueryUsingGET = function (query, authorization, page, size, sort, status, options) {
        return (0, exports.AdmissionDocumentControllerApiFp)(this.configuration).searchAdmissionsByQueryUsingGET(query, authorization, page, size, sort, status, options)(this.fetch, this.basePath);
    };
    /**
     *
     * @summary Send admission to resident. In other words set admission status to in-progress.
     * @param {string} admissionId admissionId
     * @param {string} [authorization] Authorization Header
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdmissionDocumentControllerApi
     */
    AdmissionDocumentControllerApi.prototype.sendAdmissionToResidentUsingPATCH = function (admissionId, authorization, options) {
        return (0, exports.AdmissionDocumentControllerApiFp)(this.configuration).sendAdmissionToResidentUsingPATCH(admissionId, authorization, options)(this.fetch, this.basePath);
    };
    /**
     *
     * @summary Send notification for given admission
     * @param {AdmissionNotificationModel} body admissionNotificationModel
     * @param {string} admissionId admissionId
     * @param {string} [authorization] Authorization Header
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdmissionDocumentControllerApi
     */
    AdmissionDocumentControllerApi.prototype.sendNotificationUsingPOST = function (body, admissionId, authorization, options) {
        return (0, exports.AdmissionDocumentControllerApiFp)(this.configuration).sendNotificationUsingPOST(body, admissionId, authorization, options)(this.fetch, this.basePath);
    };
    /**
     *
     * @summary Sign an admission
     * @param {string} admissionId admissionId
     * @param {string} [authorization] Authorization Header
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdmissionDocumentControllerApi
     */
    AdmissionDocumentControllerApi.prototype.signAdmissionUsingPATCH = function (admissionId, authorization, options) {
        return (0, exports.AdmissionDocumentControllerApiFp)(this.configuration).signAdmissionUsingPATCH(admissionId, authorization, options)(this.fetch, this.basePath);
    };
    /**
     *
     * @summary Synchronize with PCC webhook
     * @param {PccSyncDto} body pccSyncDto
     * @param {string} [authorization] Authorization Header
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdmissionDocumentControllerApi
     */
    AdmissionDocumentControllerApi.prototype.syncWithPccUsingPOST = function (body, authorization, options) {
        return (0, exports.AdmissionDocumentControllerApiFp)(this.configuration).syncWithPccUsingPOST(body, authorization, options)(this.fetch, this.basePath);
    };
    /**
     *
     * @summary Update admission flags
     * @param {ReadonlyArray<AdmissionFlag>} body admissionFlagsData
     * @param {string} admissionId admissionId
     * @param {string} [authorization] Authorization Header
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdmissionDocumentControllerApi
     */
    AdmissionDocumentControllerApi.prototype.updateAdmissionFlagsUsingPATCH = function (body, admissionId, authorization, options) {
        return (0, exports.AdmissionDocumentControllerApiFp)(this.configuration).updateAdmissionFlagsUsingPATCH(body, admissionId, authorization, options)(this.fetch, this.basePath);
    };
    /**
     *
     * @summary Update admission notes
     * @param {ReadonlyArray<AdmissionNote>} body admissionNotesData
     * @param {string} admissionId admissionId
     * @param {string} [authorization] Authorization Header
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdmissionDocumentControllerApi
     */
    AdmissionDocumentControllerApi.prototype.updateAdmissionNoteUsingPATCH = function (body, admissionId, authorization, options) {
        return (0, exports.AdmissionDocumentControllerApiFp)(this.configuration).updateAdmissionNoteUsingPATCH(body, admissionId, authorization, options)(this.fetch, this.basePath);
    };
    return AdmissionDocumentControllerApi;
}(BaseAPI));
exports.AdmissionDocumentControllerApi = AdmissionDocumentControllerApi;
/**
 * AdmissionEntryControllerApi - fetch parameter creator
 * @export
 */
var AdmissionEntryControllerApiFetchParamCreator = function (configuration) {
    return {
        /**
         *
         * @summary Enter admission by verifying personal data
         * @param {VerificationAnswersDto} body verificationAnswers
         * @param {string} [authorization] Authorization Header
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        enterAdmissionUsingPOST: function (body, authorization, options) {
            if (options === void 0) { options = {}; }
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body', 'Required parameter body was null or undefined when calling enterAdmissionUsingPOST.');
            }
            var localVarPath = "/api/v3/admissions/enter";
            var localVarUrlObj = url.parse(localVarPath, true);
            var localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            var localVarHeaderParameter = {};
            var localVarQueryParameter = {};
            if (authorization !== undefined && authorization !== null) {
                localVarHeaderParameter['Authorization'] = String(authorization);
            }
            localVarHeaderParameter['Content-Type'] = 'application/json';
            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            var needsSerialization = ("VerificationAnswersDto" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body = needsSerialization ? JSON.stringify(body || {}) : (body || "");
            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary Generate admission entry token
         * @param {string} admissionId admissionId
         * @param {string} [authorization] Authorization Header
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        generateAdmissionEntryTokenUsingGET: function (admissionId, authorization, options) {
            if (options === void 0) { options = {}; }
            // verify required parameter 'admissionId' is not null or undefined
            if (admissionId === null || admissionId === undefined) {
                throw new RequiredError('admissionId', 'Required parameter admissionId was null or undefined when calling generateAdmissionEntryTokenUsingGET.');
            }
            var localVarPath = "/api/v3/admissions/{admissionId}/entry-token"
                .replace("{".concat("admissionId", "}"), encodeURIComponent(String(admissionId)));
            var localVarUrlObj = url.parse(localVarPath, true);
            var localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            var localVarHeaderParameter = {};
            var localVarQueryParameter = {};
            if (authorization !== undefined && authorization !== null) {
                localVarHeaderParameter['Authorization'] = String(authorization);
            }
            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary Send email with an admission entry link
         * @param {EmailAdmissionEntryLinkDto} body entryLinkDto
         * @param {string} [authorization] Authorization Header
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sendAdmissionEntryLinkEmailUsingPOST: function (body, authorization, options) {
            if (options === void 0) { options = {}; }
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body', 'Required parameter body was null or undefined when calling sendAdmissionEntryLinkEmailUsingPOST.');
            }
            var localVarPath = "/api/v3/admissions/auth/email-login-link";
            var localVarUrlObj = url.parse(localVarPath, true);
            var localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            var localVarHeaderParameter = {};
            var localVarQueryParameter = {};
            if (authorization !== undefined && authorization !== null) {
                localVarHeaderParameter['Authorization'] = String(authorization);
            }
            localVarHeaderParameter['Content-Type'] = 'application/json';
            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            var needsSerialization = ("EmailAdmissionEntryLinkDto" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body = needsSerialization ? JSON.stringify(body || {}) : (body || "");
            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    };
};
exports.AdmissionEntryControllerApiFetchParamCreator = AdmissionEntryControllerApiFetchParamCreator;
/**
 * AdmissionEntryControllerApi - functional programming interface
 * @export
 */
var AdmissionEntryControllerApiFp = function (configuration) {
    return {
        /**
         *
         * @summary Enter admission by verifying personal data
         * @param {VerificationAnswersDto} body verificationAnswers
         * @param {string} [authorization] Authorization Header
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        enterAdmissionUsingPOST: function (body, authorization, options) {
            var localVarFetchArgs = (0, exports.AdmissionEntryControllerApiFetchParamCreator)(configuration).enterAdmissionUsingPOST(body, authorization, options);
            return function (fetch, basePath) {
                if (fetch === void 0) { fetch = portableFetch; }
                if (basePath === void 0) { basePath = BASE_PATH; }
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then(function (response) {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json().then(function (data) { return (__assign(__assign({}, response), { data: data })); });
                    }
                    else {
                        throw response;
                    }
                });
            };
        },
        /**
         *
         * @summary Generate admission entry token
         * @param {string} admissionId admissionId
         * @param {string} [authorization] Authorization Header
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        generateAdmissionEntryTokenUsingGET: function (admissionId, authorization, options) {
            var localVarFetchArgs = (0, exports.AdmissionEntryControllerApiFetchParamCreator)(configuration).generateAdmissionEntryTokenUsingGET(admissionId, authorization, options);
            return function (fetch, basePath) {
                if (fetch === void 0) { fetch = portableFetch; }
                if (basePath === void 0) { basePath = BASE_PATH; }
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then(function (response) {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json().then(function (data) { return (__assign(__assign({}, response), { data: data })); });
                    }
                    else {
                        throw response;
                    }
                });
            };
        },
        /**
         *
         * @summary Send email with an admission entry link
         * @param {EmailAdmissionEntryLinkDto} body entryLinkDto
         * @param {string} [authorization] Authorization Header
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sendAdmissionEntryLinkEmailUsingPOST: function (body, authorization, options) {
            var localVarFetchArgs = (0, exports.AdmissionEntryControllerApiFetchParamCreator)(configuration).sendAdmissionEntryLinkEmailUsingPOST(body, authorization, options);
            return function (fetch, basePath) {
                if (fetch === void 0) { fetch = portableFetch; }
                if (basePath === void 0) { basePath = BASE_PATH; }
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then(function (response) {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json().then(function (data) { return (__assign(__assign({}, response), { data: data })); });
                    }
                    else {
                        throw response;
                    }
                });
            };
        },
    };
};
exports.AdmissionEntryControllerApiFp = AdmissionEntryControllerApiFp;
/**
 * AdmissionEntryControllerApi - factory interface
 * @export
 */
var AdmissionEntryControllerApiFactory = function (configuration, fetch, basePath) {
    return {
        /**
         *
         * @summary Enter admission by verifying personal data
         * @param {VerificationAnswersDto} body verificationAnswers
         * @param {string} [authorization] Authorization Header
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        enterAdmissionUsingPOST: function (body, authorization, options) {
            return (0, exports.AdmissionEntryControllerApiFp)(configuration).enterAdmissionUsingPOST(body, authorization, options)(fetch, basePath);
        },
        /**
         *
         * @summary Generate admission entry token
         * @param {string} admissionId admissionId
         * @param {string} [authorization] Authorization Header
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        generateAdmissionEntryTokenUsingGET: function (admissionId, authorization, options) {
            return (0, exports.AdmissionEntryControllerApiFp)(configuration).generateAdmissionEntryTokenUsingGET(admissionId, authorization, options)(fetch, basePath);
        },
        /**
         *
         * @summary Send email with an admission entry link
         * @param {EmailAdmissionEntryLinkDto} body entryLinkDto
         * @param {string} [authorization] Authorization Header
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sendAdmissionEntryLinkEmailUsingPOST: function (body, authorization, options) {
            return (0, exports.AdmissionEntryControllerApiFp)(configuration).sendAdmissionEntryLinkEmailUsingPOST(body, authorization, options)(fetch, basePath);
        },
    };
};
exports.AdmissionEntryControllerApiFactory = AdmissionEntryControllerApiFactory;
/**
 * AdmissionEntryControllerApi - object-oriented interface
 * @export
 * @class AdmissionEntryControllerApi
 * @extends {BaseAPI}
 */
var AdmissionEntryControllerApi = /** @class */ (function (_super) {
    __extends(AdmissionEntryControllerApi, _super);
    function AdmissionEntryControllerApi() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    /**
     *
     * @summary Enter admission by verifying personal data
     * @param {VerificationAnswersDto} body verificationAnswers
     * @param {string} [authorization] Authorization Header
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdmissionEntryControllerApi
     */
    AdmissionEntryControllerApi.prototype.enterAdmissionUsingPOST = function (body, authorization, options) {
        return (0, exports.AdmissionEntryControllerApiFp)(this.configuration).enterAdmissionUsingPOST(body, authorization, options)(this.fetch, this.basePath);
    };
    /**
     *
     * @summary Generate admission entry token
     * @param {string} admissionId admissionId
     * @param {string} [authorization] Authorization Header
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdmissionEntryControllerApi
     */
    AdmissionEntryControllerApi.prototype.generateAdmissionEntryTokenUsingGET = function (admissionId, authorization, options) {
        return (0, exports.AdmissionEntryControllerApiFp)(this.configuration).generateAdmissionEntryTokenUsingGET(admissionId, authorization, options)(this.fetch, this.basePath);
    };
    /**
     *
     * @summary Send email with an admission entry link
     * @param {EmailAdmissionEntryLinkDto} body entryLinkDto
     * @param {string} [authorization] Authorization Header
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdmissionEntryControllerApi
     */
    AdmissionEntryControllerApi.prototype.sendAdmissionEntryLinkEmailUsingPOST = function (body, authorization, options) {
        return (0, exports.AdmissionEntryControllerApiFp)(this.configuration).sendAdmissionEntryLinkEmailUsingPOST(body, authorization, options)(this.fetch, this.basePath);
    };
    return AdmissionEntryControllerApi;
}(BaseAPI));
exports.AdmissionEntryControllerApi = AdmissionEntryControllerApi;
/**
 * PointClickCareControllerApi - fetch parameter creator
 * @export
 */
var PointClickCareControllerApiFetchParamCreator = function (configuration) {
    return {
        /**
         *
         * @summary Checks if PointClickCare integration is enabled
         * @param {string} facilityId facilityId
         * @param {string} [authorization] Authorization Header
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        checkPccIntegrationStatusUsingGET: function (facilityId, authorization, options) {
            if (options === void 0) { options = {}; }
            // verify required parameter 'facilityId' is not null or undefined
            if (facilityId === null || facilityId === undefined) {
                throw new RequiredError('facilityId', 'Required parameter facilityId was null or undefined when calling checkPccIntegrationStatusUsingGET.');
            }
            var localVarPath = "/api/point-click-care/facilities/{facilityId}/integration-status"
                .replace("{".concat("facilityId", "}"), encodeURIComponent(String(facilityId)));
            var localVarUrlObj = url.parse(localVarPath, true);
            var localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            var localVarHeaderParameter = {};
            var localVarQueryParameter = {};
            if (authorization !== undefined && authorization !== null) {
                localVarHeaderParameter['Authorization'] = String(authorization);
            }
            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary Checks if PointClickCare preflight populating flow is enabled for given facility and event type
         * @param {string} eventListenerGroupType eventListenerGroupType
         * @param {string} facilityId facilityId
         * @param {string} [authorization] Authorization Header
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        checkPreflightPopulatingFlowEnabledUsingGET: function (eventListenerGroupType, facilityId, authorization, options) {
            if (options === void 0) { options = {}; }
            // verify required parameter 'eventListenerGroupType' is not null or undefined
            if (eventListenerGroupType === null || eventListenerGroupType === undefined) {
                throw new RequiredError('eventListenerGroupType', 'Required parameter eventListenerGroupType was null or undefined when calling checkPreflightPopulatingFlowEnabledUsingGET.');
            }
            // verify required parameter 'facilityId' is not null or undefined
            if (facilityId === null || facilityId === undefined) {
                throw new RequiredError('facilityId', 'Required parameter facilityId was null or undefined when calling checkPreflightPopulatingFlowEnabledUsingGET.');
            }
            var localVarPath = "/api/point-click-care/facilities/{facilityId}/populating-flow-status"
                .replace("{".concat("facilityId", "}"), encodeURIComponent(String(facilityId)));
            var localVarUrlObj = url.parse(localVarPath, true);
            var localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            var localVarHeaderParameter = {};
            var localVarQueryParameter = {};
            if (eventListenerGroupType !== undefined) {
                localVarQueryParameter['eventListenerGroupType'] = eventListenerGroupType;
            }
            if (authorization !== undefined && authorization !== null) {
                localVarHeaderParameter['Authorization'] = String(authorization);
            }
            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary Check if resident has PointClickCare account
         * @param {string} admissionId admissionId
         * @param {string} facilityId facilityId
         * @param {string} [authorization] Authorization Header
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        checkResidentPccAccountStatusUsingGET: function (admissionId, facilityId, authorization, options) {
            if (options === void 0) { options = {}; }
            // verify required parameter 'admissionId' is not null or undefined
            if (admissionId === null || admissionId === undefined) {
                throw new RequiredError('admissionId', 'Required parameter admissionId was null or undefined when calling checkResidentPccAccountStatusUsingGET.');
            }
            // verify required parameter 'facilityId' is not null or undefined
            if (facilityId === null || facilityId === undefined) {
                throw new RequiredError('facilityId', 'Required parameter facilityId was null or undefined when calling checkResidentPccAccountStatusUsingGET.');
            }
            var localVarPath = "/api/point-click-care/account-status";
            var localVarUrlObj = url.parse(localVarPath, true);
            var localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            var localVarHeaderParameter = {};
            var localVarQueryParameter = {};
            if (admissionId !== undefined) {
                localVarQueryParameter['admissionId'] = admissionId;
            }
            if (facilityId !== undefined) {
                localVarQueryParameter['facilityId'] = facilityId;
            }
            if (authorization !== undefined && authorization !== null) {
                localVarHeaderParameter['Authorization'] = String(authorization);
            }
            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary Returns list of residents based on criteria
         * @param {string} birthDate birthDate
         * @param {string} facilityId facilityId
         * @param {string} firstName firstName
         * @param {string} gender gender
         * @param {string} lastName lastName
         * @param {string} [authorization] Authorization Header
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findResidentsUsingGET: function (birthDate, facilityId, firstName, gender, lastName, authorization, options) {
            if (options === void 0) { options = {}; }
            // verify required parameter 'birthDate' is not null or undefined
            if (birthDate === null || birthDate === undefined) {
                throw new RequiredError('birthDate', 'Required parameter birthDate was null or undefined when calling findResidentsUsingGET.');
            }
            // verify required parameter 'facilityId' is not null or undefined
            if (facilityId === null || facilityId === undefined) {
                throw new RequiredError('facilityId', 'Required parameter facilityId was null or undefined when calling findResidentsUsingGET.');
            }
            // verify required parameter 'firstName' is not null or undefined
            if (firstName === null || firstName === undefined) {
                throw new RequiredError('firstName', 'Required parameter firstName was null or undefined when calling findResidentsUsingGET.');
            }
            // verify required parameter 'gender' is not null or undefined
            if (gender === null || gender === undefined) {
                throw new RequiredError('gender', 'Required parameter gender was null or undefined when calling findResidentsUsingGET.');
            }
            // verify required parameter 'lastName' is not null or undefined
            if (lastName === null || lastName === undefined) {
                throw new RequiredError('lastName', 'Required parameter lastName was null or undefined when calling findResidentsUsingGET.');
            }
            var localVarPath = "/api/point-click-care/residents";
            var localVarUrlObj = url.parse(localVarPath, true);
            var localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            var localVarHeaderParameter = {};
            var localVarQueryParameter = {};
            if (birthDate !== undefined) {
                localVarQueryParameter['birthDate'] = birthDate.toISOString();
            }
            if (facilityId !== undefined) {
                localVarQueryParameter['facilityId'] = facilityId;
            }
            if (firstName !== undefined) {
                localVarQueryParameter['firstName'] = firstName;
            }
            if (gender !== undefined) {
                localVarQueryParameter['gender'] = gender;
            }
            if (lastName !== undefined) {
                localVarQueryParameter['lastName'] = lastName;
            }
            if (authorization !== undefined && authorization !== null) {
                localVarHeaderParameter['Authorization'] = String(authorization);
            }
            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary Returns facility based activity logs for authenticated users. Pagination is mandatory, default is page=0&size=20&sort=time,desc. Search params: ?facilityId=facilityIdentifier || ?organizationId=organizationIdentifier
         * @param {string} facilityId facilityId
         * @param {string} [authorization] Authorization Header
         * @param {string} [organizationId] organizationId
         * @param {number} [page]
         * @param {number} [size]
         * @param {ReadonlyArray<string>} [sort]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getActivityLogRecordsUsingGET: function (facilityId, authorization, organizationId, page, size, sort, options) {
            if (options === void 0) { options = {}; }
            // verify required parameter 'facilityId' is not null or undefined
            if (facilityId === null || facilityId === undefined) {
                throw new RequiredError('facilityId', 'Required parameter facilityId was null or undefined when calling getActivityLogRecordsUsingGET.');
            }
            var localVarPath = "/api/point-click-care/activity-logs";
            var localVarUrlObj = url.parse(localVarPath, true);
            var localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            var localVarHeaderParameter = {};
            var localVarQueryParameter = {};
            if (facilityId !== undefined) {
                localVarQueryParameter['facilityId'] = facilityId;
            }
            if (organizationId !== undefined) {
                localVarQueryParameter['organizationId'] = organizationId;
            }
            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }
            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }
            if (sort) {
                localVarQueryParameter['sort'] = sort;
            }
            if (authorization !== undefined && authorization !== null) {
                localVarHeaderParameter['Authorization'] = String(authorization);
            }
            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary Returns document upload categories by facility ID
         * @param {string} facilityId facilityId
         * @param {string} [authorization] Authorization Header
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDocumentUploadCategoriesUsingGET: function (facilityId, authorization, options) {
            if (options === void 0) { options = {}; }
            // verify required parameter 'facilityId' is not null or undefined
            if (facilityId === null || facilityId === undefined) {
                throw new RequiredError('facilityId', 'Required parameter facilityId was null or undefined when calling getDocumentUploadCategoriesUsingGET.');
            }
            var localVarPath = "/api/point-click-care/facilities/{facilityId}/document-upload-categories"
                .replace("{".concat("facilityId", "}"), encodeURIComponent(String(facilityId)));
            var localVarUrlObj = url.parse(localVarPath, true);
            var localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            var localVarHeaderParameter = {};
            var localVarQueryParameter = {};
            if (authorization !== undefined && authorization !== null) {
                localVarHeaderParameter['Authorization'] = String(authorization);
            }
            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary Returns resident by ID
         * @param {string} facilityId facilityId
         * @param {string} [authorization] Authorization Header
         * @param {string} [admissionId] admissionId
         * @param {number} [patientId] patientId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getResidentUsingGET: function (facilityId, authorization, admissionId, patientId, options) {
            if (options === void 0) { options = {}; }
            // verify required parameter 'facilityId' is not null or undefined
            if (facilityId === null || facilityId === undefined) {
                throw new RequiredError('facilityId', 'Required parameter facilityId was null or undefined when calling getResidentUsingGET.');
            }
            var localVarPath = "/api/point-click-care/resident";
            var localVarUrlObj = url.parse(localVarPath, true);
            var localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            var localVarHeaderParameter = {};
            var localVarQueryParameter = {};
            if (admissionId !== undefined) {
                localVarQueryParameter['admissionId'] = admissionId;
            }
            if (facilityId !== undefined) {
                localVarQueryParameter['facilityId'] = facilityId;
            }
            if (patientId !== undefined) {
                localVarQueryParameter['patientId'] = patientId;
            }
            if (authorization !== undefined && authorization !== null) {
                localVarHeaderParameter['Authorization'] = String(authorization);
            }
            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary Create new PointClickCare account and appropriate mapping
         * @param {CreateAccountDto} body createAccountDto
         * @param {string} [authorization] Authorization Header
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        requestNewPccAccountUsingPOST: function (body, authorization, options) {
            if (options === void 0) { options = {}; }
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body', 'Required parameter body was null or undefined when calling requestNewPccAccountUsingPOST.');
            }
            var localVarPath = "/api/point-click-care/accounts";
            var localVarUrlObj = url.parse(localVarPath, true);
            var localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            var localVarHeaderParameter = {};
            var localVarQueryParameter = {};
            if (authorization !== undefined && authorization !== null) {
                localVarHeaderParameter['Authorization'] = String(authorization);
            }
            localVarHeaderParameter['Content-Type'] = 'application/json';
            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            var needsSerialization = ("CreateAccountDto" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body = needsSerialization ? JSON.stringify(body || {}) : (body || "");
            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary Retries activity specified by id
         * @param {RetryActivityDto} body retryActivityDto
         * @param {string} [authorization] Authorization Header
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        retryActivityUsingPOST: function (body, authorization, options) {
            if (options === void 0) { options = {}; }
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body', 'Required parameter body was null or undefined when calling retryActivityUsingPOST.');
            }
            var localVarPath = "/api/point-click-care/activity-logs/retry";
            var localVarUrlObj = url.parse(localVarPath, true);
            var localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            var localVarHeaderParameter = {};
            var localVarQueryParameter = {};
            if (authorization !== undefined && authorization !== null) {
                localVarHeaderParameter['Authorization'] = String(authorization);
            }
            localVarHeaderParameter['Content-Type'] = 'application/json';
            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            var needsSerialization = ("RetryActivityDto" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body = needsSerialization ? JSON.stringify(body || {}) : (body || "");
            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary Skips creating PointClickCare account for specified patient/resident
         * @param {CreateAccountDto} body createAccountDto
         * @param {string} [authorization] Authorization Header
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        skipPccAccountUsingPOST: function (body, authorization, options) {
            if (options === void 0) { options = {}; }
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body', 'Required parameter body was null or undefined when calling skipPccAccountUsingPOST.');
            }
            var localVarPath = "/api/point-click-care/accounts/skipped";
            var localVarUrlObj = url.parse(localVarPath, true);
            var localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            var localVarHeaderParameter = {};
            var localVarQueryParameter = {};
            if (authorization !== undefined && authorization !== null) {
                localVarHeaderParameter['Authorization'] = String(authorization);
            }
            localVarHeaderParameter['Content-Type'] = 'application/json';
            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            var needsSerialization = ("CreateAccountDto" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body = needsSerialization ? JSON.stringify(body || {}) : (body || "");
            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary Uploads checklist item document to PCC
         * @param {Blob} file
         * @param {string} admissionId admissionId
         * @param {string} checklistItemId checklistItemId
         * @param {number} documentCategoryId documentCategoryId
         * @param {string} [authorization] Authorization Header
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadChecklistItemDocumentUsingPOST: function (file, admissionId, checklistItemId, documentCategoryId, authorization, options) {
            if (options === void 0) { options = {}; }
            // verify required parameter 'file' is not null or undefined
            if (file === null || file === undefined) {
                throw new RequiredError('file', 'Required parameter file was null or undefined when calling uploadChecklistItemDocumentUsingPOST.');
            }
            // verify required parameter 'admissionId' is not null or undefined
            if (admissionId === null || admissionId === undefined) {
                throw new RequiredError('admissionId', 'Required parameter admissionId was null or undefined when calling uploadChecklistItemDocumentUsingPOST.');
            }
            // verify required parameter 'checklistItemId' is not null or undefined
            if (checklistItemId === null || checklistItemId === undefined) {
                throw new RequiredError('checklistItemId', 'Required parameter checklistItemId was null or undefined when calling uploadChecklistItemDocumentUsingPOST.');
            }
            // verify required parameter 'documentCategoryId' is not null or undefined
            if (documentCategoryId === null || documentCategoryId === undefined) {
                throw new RequiredError('documentCategoryId', 'Required parameter documentCategoryId was null or undefined when calling uploadChecklistItemDocumentUsingPOST.');
            }
            var localVarPath = "/api/point-click-care/integrations/admissions/{admissionId}/checklist-items/{checklistItemId}/document-upload"
                .replace("{".concat("admissionId", "}"), encodeURIComponent(String(admissionId)))
                .replace("{".concat("checklistItemId", "}"), encodeURIComponent(String(checklistItemId)));
            var localVarUrlObj = url.parse(localVarPath, true);
            var localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            var localVarHeaderParameter = {};
            var localVarQueryParameter = {};
            var localVarFormParams = new url.URLSearchParams();
            if (documentCategoryId !== undefined) {
                localVarQueryParameter['documentCategoryId'] = documentCategoryId;
            }
            if (authorization !== undefined && authorization !== null) {
                localVarHeaderParameter['Authorization'] = String(authorization);
            }
            if (file !== undefined) {
                localVarFormParams.set('file', file);
            }
            localVarHeaderParameter['Content-Type'] = 'application/x-www-form-urlencoded';
            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            localVarRequestOptions.body = localVarFormParams.toString();
            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary Upload generated packet of given admission and store returned document identifier
         * @param {string} admissionId admissionId
         * @param {string} [authorization] Authorization Header
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadPacketUsingPUT: function (admissionId, authorization, options) {
            if (options === void 0) { options = {}; }
            // verify required parameter 'admissionId' is not null or undefined
            if (admissionId === null || admissionId === undefined) {
                throw new RequiredError('admissionId', 'Required parameter admissionId was null or undefined when calling uploadPacketUsingPUT.');
            }
            var localVarPath = "/api/point-click-care/integrations/admissions/{admissionId}"
                .replace("{".concat("admissionId", "}"), encodeURIComponent(String(admissionId)));
            var localVarUrlObj = url.parse(localVarPath, true);
            var localVarRequestOptions = Object.assign({ method: 'PUT' }, options);
            var localVarHeaderParameter = {};
            var localVarQueryParameter = {};
            if (authorization !== undefined && authorization !== null) {
                localVarHeaderParameter['Authorization'] = String(authorization);
            }
            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary Endpoint called once pending PointClickCare account gets active on PointClickCare side
         * @param {string} body payload
         * @param {string} [authorization] Authorization Header
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        webHookUsingPOST: function (body, authorization, options) {
            if (options === void 0) { options = {}; }
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body', 'Required parameter body was null or undefined when calling webHookUsingPOST.');
            }
            var localVarPath = "/api/point-click-care/events/process-pending-accounts";
            var localVarUrlObj = url.parse(localVarPath, true);
            var localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            var localVarHeaderParameter = {};
            var localVarQueryParameter = {};
            if (authorization !== undefined && authorization !== null) {
                localVarHeaderParameter['Authorization'] = String(authorization);
            }
            localVarHeaderParameter['Content-Type'] = 'application/json';
            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            var needsSerialization = ("string" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body = needsSerialization ? JSON.stringify(body || {}) : (body || "");
            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    };
};
exports.PointClickCareControllerApiFetchParamCreator = PointClickCareControllerApiFetchParamCreator;
/**
 * PointClickCareControllerApi - functional programming interface
 * @export
 */
var PointClickCareControllerApiFp = function (configuration) {
    return {
        /**
         *
         * @summary Checks if PointClickCare integration is enabled
         * @param {string} facilityId facilityId
         * @param {string} [authorization] Authorization Header
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        checkPccIntegrationStatusUsingGET: function (facilityId, authorization, options) {
            var localVarFetchArgs = (0, exports.PointClickCareControllerApiFetchParamCreator)(configuration).checkPccIntegrationStatusUsingGET(facilityId, authorization, options);
            return function (fetch, basePath) {
                if (fetch === void 0) { fetch = portableFetch; }
                if (basePath === void 0) { basePath = BASE_PATH; }
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then(function (response) {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json().then(function (data) { return (__assign(__assign({}, response), { data: data })); });
                    }
                    else {
                        throw response;
                    }
                });
            };
        },
        /**
         *
         * @summary Checks if PointClickCare preflight populating flow is enabled for given facility and event type
         * @param {string} eventListenerGroupType eventListenerGroupType
         * @param {string} facilityId facilityId
         * @param {string} [authorization] Authorization Header
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        checkPreflightPopulatingFlowEnabledUsingGET: function (eventListenerGroupType, facilityId, authorization, options) {
            var localVarFetchArgs = (0, exports.PointClickCareControllerApiFetchParamCreator)(configuration).checkPreflightPopulatingFlowEnabledUsingGET(eventListenerGroupType, facilityId, authorization, options);
            return function (fetch, basePath) {
                if (fetch === void 0) { fetch = portableFetch; }
                if (basePath === void 0) { basePath = BASE_PATH; }
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then(function (response) {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json().then(function (data) { return (__assign(__assign({}, response), { data: data })); });
                    }
                    else {
                        throw response;
                    }
                });
            };
        },
        /**
         *
         * @summary Check if resident has PointClickCare account
         * @param {string} admissionId admissionId
         * @param {string} facilityId facilityId
         * @param {string} [authorization] Authorization Header
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        checkResidentPccAccountStatusUsingGET: function (admissionId, facilityId, authorization, options) {
            var localVarFetchArgs = (0, exports.PointClickCareControllerApiFetchParamCreator)(configuration).checkResidentPccAccountStatusUsingGET(admissionId, facilityId, authorization, options);
            return function (fetch, basePath) {
                if (fetch === void 0) { fetch = portableFetch; }
                if (basePath === void 0) { basePath = BASE_PATH; }
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then(function (response) {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json().then(function (data) { return (__assign(__assign({}, response), { data: data })); });
                    }
                    else {
                        throw response;
                    }
                });
            };
        },
        /**
         *
         * @summary Returns list of residents based on criteria
         * @param {string} birthDate birthDate
         * @param {string} facilityId facilityId
         * @param {string} firstName firstName
         * @param {string} gender gender
         * @param {string} lastName lastName
         * @param {string} [authorization] Authorization Header
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findResidentsUsingGET: function (birthDate, facilityId, firstName, gender, lastName, authorization, options) {
            var localVarFetchArgs = (0, exports.PointClickCareControllerApiFetchParamCreator)(configuration).findResidentsUsingGET(birthDate, facilityId, firstName, gender, lastName, authorization, options);
            return function (fetch, basePath) {
                if (fetch === void 0) { fetch = portableFetch; }
                if (basePath === void 0) { basePath = BASE_PATH; }
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then(function (response) {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json().then(function (data) { return (__assign(__assign({}, response), { data: data })); });
                    }
                    else {
                        throw response;
                    }
                });
            };
        },
        /**
         *
         * @summary Returns facility based activity logs for authenticated users. Pagination is mandatory, default is page=0&size=20&sort=time,desc. Search params: ?facilityId=facilityIdentifier || ?organizationId=organizationIdentifier
         * @param {string} facilityId facilityId
         * @param {string} [authorization] Authorization Header
         * @param {string} [organizationId] organizationId
         * @param {number} [page]
         * @param {number} [size]
         * @param {ReadonlyArray<string>} [sort]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getActivityLogRecordsUsingGET: function (facilityId, authorization, organizationId, page, size, sort, options) {
            var localVarFetchArgs = (0, exports.PointClickCareControllerApiFetchParamCreator)(configuration).getActivityLogRecordsUsingGET(facilityId, authorization, organizationId, page, size, sort, options);
            return function (fetch, basePath) {
                if (fetch === void 0) { fetch = portableFetch; }
                if (basePath === void 0) { basePath = BASE_PATH; }
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then(function (response) {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json().then(function (data) { return (__assign(__assign({}, response), { data: data })); });
                    }
                    else {
                        throw response;
                    }
                });
            };
        },
        /**
         *
         * @summary Returns document upload categories by facility ID
         * @param {string} facilityId facilityId
         * @param {string} [authorization] Authorization Header
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDocumentUploadCategoriesUsingGET: function (facilityId, authorization, options) {
            var localVarFetchArgs = (0, exports.PointClickCareControllerApiFetchParamCreator)(configuration).getDocumentUploadCategoriesUsingGET(facilityId, authorization, options);
            return function (fetch, basePath) {
                if (fetch === void 0) { fetch = portableFetch; }
                if (basePath === void 0) { basePath = BASE_PATH; }
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then(function (response) {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json().then(function (data) { return (__assign(__assign({}, response), { data: data })); });
                    }
                    else {
                        throw response;
                    }
                });
            };
        },
        /**
         *
         * @summary Returns resident by ID
         * @param {string} facilityId facilityId
         * @param {string} [authorization] Authorization Header
         * @param {string} [admissionId] admissionId
         * @param {number} [patientId] patientId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getResidentUsingGET: function (facilityId, authorization, admissionId, patientId, options) {
            var localVarFetchArgs = (0, exports.PointClickCareControllerApiFetchParamCreator)(configuration).getResidentUsingGET(facilityId, authorization, admissionId, patientId, options);
            return function (fetch, basePath) {
                if (fetch === void 0) { fetch = portableFetch; }
                if (basePath === void 0) { basePath = BASE_PATH; }
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then(function (response) {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json().then(function (data) { return (__assign(__assign({}, response), { data: data })); });
                    }
                    else {
                        throw response;
                    }
                });
            };
        },
        /**
         *
         * @summary Create new PointClickCare account and appropriate mapping
         * @param {CreateAccountDto} body createAccountDto
         * @param {string} [authorization] Authorization Header
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        requestNewPccAccountUsingPOST: function (body, authorization, options) {
            var localVarFetchArgs = (0, exports.PointClickCareControllerApiFetchParamCreator)(configuration).requestNewPccAccountUsingPOST(body, authorization, options);
            return function (fetch, basePath) {
                if (fetch === void 0) { fetch = portableFetch; }
                if (basePath === void 0) { basePath = BASE_PATH; }
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then(function (response) {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json().then(function (data) { return (__assign(__assign({}, response), { data: data })); });
                    }
                    else {
                        throw response;
                    }
                });
            };
        },
        /**
         *
         * @summary Retries activity specified by id
         * @param {RetryActivityDto} body retryActivityDto
         * @param {string} [authorization] Authorization Header
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        retryActivityUsingPOST: function (body, authorization, options) {
            var localVarFetchArgs = (0, exports.PointClickCareControllerApiFetchParamCreator)(configuration).retryActivityUsingPOST(body, authorization, options);
            return function (fetch, basePath) {
                if (fetch === void 0) { fetch = portableFetch; }
                if (basePath === void 0) { basePath = BASE_PATH; }
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then(function (response) {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json().then(function (data) { return (__assign(__assign({}, response), { data: data })); });
                    }
                    else {
                        throw response;
                    }
                });
            };
        },
        /**
         *
         * @summary Skips creating PointClickCare account for specified patient/resident
         * @param {CreateAccountDto} body createAccountDto
         * @param {string} [authorization] Authorization Header
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        skipPccAccountUsingPOST: function (body, authorization, options) {
            var localVarFetchArgs = (0, exports.PointClickCareControllerApiFetchParamCreator)(configuration).skipPccAccountUsingPOST(body, authorization, options);
            return function (fetch, basePath) {
                if (fetch === void 0) { fetch = portableFetch; }
                if (basePath === void 0) { basePath = BASE_PATH; }
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then(function (response) {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json().then(function (data) { return (__assign(__assign({}, response), { data: data })); });
                    }
                    else {
                        throw response;
                    }
                });
            };
        },
        /**
         *
         * @summary Uploads checklist item document to PCC
         * @param {Blob} file
         * @param {string} admissionId admissionId
         * @param {string} checklistItemId checklistItemId
         * @param {number} documentCategoryId documentCategoryId
         * @param {string} [authorization] Authorization Header
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadChecklistItemDocumentUsingPOST: function (file, admissionId, checklistItemId, documentCategoryId, authorization, options) {
            var localVarFetchArgs = (0, exports.PointClickCareControllerApiFetchParamCreator)(configuration).uploadChecklistItemDocumentUsingPOST(file, admissionId, checklistItemId, documentCategoryId, authorization, options);
            return function (fetch, basePath) {
                if (fetch === void 0) { fetch = portableFetch; }
                if (basePath === void 0) { basePath = BASE_PATH; }
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then(function (response) {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json().then(function (data) { return (__assign(__assign({}, response), { data: data })); });
                    }
                    else {
                        throw response;
                    }
                });
            };
        },
        /**
         *
         * @summary Upload generated packet of given admission and store returned document identifier
         * @param {string} admissionId admissionId
         * @param {string} [authorization] Authorization Header
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadPacketUsingPUT: function (admissionId, authorization, options) {
            var localVarFetchArgs = (0, exports.PointClickCareControllerApiFetchParamCreator)(configuration).uploadPacketUsingPUT(admissionId, authorization, options);
            return function (fetch, basePath) {
                if (fetch === void 0) { fetch = portableFetch; }
                if (basePath === void 0) { basePath = BASE_PATH; }
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then(function (response) {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    }
                    else {
                        throw response;
                    }
                });
            };
        },
        /**
         *
         * @summary Endpoint called once pending PointClickCare account gets active on PointClickCare side
         * @param {string} body payload
         * @param {string} [authorization] Authorization Header
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        webHookUsingPOST: function (body, authorization, options) {
            var localVarFetchArgs = (0, exports.PointClickCareControllerApiFetchParamCreator)(configuration).webHookUsingPOST(body, authorization, options);
            return function (fetch, basePath) {
                if (fetch === void 0) { fetch = portableFetch; }
                if (basePath === void 0) { basePath = BASE_PATH; }
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then(function (response) {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    }
                    else {
                        throw response;
                    }
                });
            };
        },
    };
};
exports.PointClickCareControllerApiFp = PointClickCareControllerApiFp;
/**
 * PointClickCareControllerApi - factory interface
 * @export
 */
var PointClickCareControllerApiFactory = function (configuration, fetch, basePath) {
    return {
        /**
         *
         * @summary Checks if PointClickCare integration is enabled
         * @param {string} facilityId facilityId
         * @param {string} [authorization] Authorization Header
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        checkPccIntegrationStatusUsingGET: function (facilityId, authorization, options) {
            return (0, exports.PointClickCareControllerApiFp)(configuration).checkPccIntegrationStatusUsingGET(facilityId, authorization, options)(fetch, basePath);
        },
        /**
         *
         * @summary Checks if PointClickCare preflight populating flow is enabled for given facility and event type
         * @param {string} eventListenerGroupType eventListenerGroupType
         * @param {string} facilityId facilityId
         * @param {string} [authorization] Authorization Header
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        checkPreflightPopulatingFlowEnabledUsingGET: function (eventListenerGroupType, facilityId, authorization, options) {
            return (0, exports.PointClickCareControllerApiFp)(configuration).checkPreflightPopulatingFlowEnabledUsingGET(eventListenerGroupType, facilityId, authorization, options)(fetch, basePath);
        },
        /**
         *
         * @summary Check if resident has PointClickCare account
         * @param {string} admissionId admissionId
         * @param {string} facilityId facilityId
         * @param {string} [authorization] Authorization Header
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        checkResidentPccAccountStatusUsingGET: function (admissionId, facilityId, authorization, options) {
            return (0, exports.PointClickCareControllerApiFp)(configuration).checkResidentPccAccountStatusUsingGET(admissionId, facilityId, authorization, options)(fetch, basePath);
        },
        /**
         *
         * @summary Returns list of residents based on criteria
         * @param {string} birthDate birthDate
         * @param {string} facilityId facilityId
         * @param {string} firstName firstName
         * @param {string} gender gender
         * @param {string} lastName lastName
         * @param {string} [authorization] Authorization Header
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findResidentsUsingGET: function (birthDate, facilityId, firstName, gender, lastName, authorization, options) {
            return (0, exports.PointClickCareControllerApiFp)(configuration).findResidentsUsingGET(birthDate, facilityId, firstName, gender, lastName, authorization, options)(fetch, basePath);
        },
        /**
         *
         * @summary Returns facility based activity logs for authenticated users. Pagination is mandatory, default is page=0&size=20&sort=time,desc. Search params: ?facilityId=facilityIdentifier || ?organizationId=organizationIdentifier
         * @param {string} facilityId facilityId
         * @param {string} [authorization] Authorization Header
         * @param {string} [organizationId] organizationId
         * @param {number} [page]
         * @param {number} [size]
         * @param {ReadonlyArray<string>} [sort]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getActivityLogRecordsUsingGET: function (facilityId, authorization, organizationId, page, size, sort, options) {
            return (0, exports.PointClickCareControllerApiFp)(configuration).getActivityLogRecordsUsingGET(facilityId, authorization, organizationId, page, size, sort, options)(fetch, basePath);
        },
        /**
         *
         * @summary Returns document upload categories by facility ID
         * @param {string} facilityId facilityId
         * @param {string} [authorization] Authorization Header
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDocumentUploadCategoriesUsingGET: function (facilityId, authorization, options) {
            return (0, exports.PointClickCareControllerApiFp)(configuration).getDocumentUploadCategoriesUsingGET(facilityId, authorization, options)(fetch, basePath);
        },
        /**
         *
         * @summary Returns resident by ID
         * @param {string} facilityId facilityId
         * @param {string} [authorization] Authorization Header
         * @param {string} [admissionId] admissionId
         * @param {number} [patientId] patientId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getResidentUsingGET: function (facilityId, authorization, admissionId, patientId, options) {
            return (0, exports.PointClickCareControllerApiFp)(configuration).getResidentUsingGET(facilityId, authorization, admissionId, patientId, options)(fetch, basePath);
        },
        /**
         *
         * @summary Create new PointClickCare account and appropriate mapping
         * @param {CreateAccountDto} body createAccountDto
         * @param {string} [authorization] Authorization Header
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        requestNewPccAccountUsingPOST: function (body, authorization, options) {
            return (0, exports.PointClickCareControllerApiFp)(configuration).requestNewPccAccountUsingPOST(body, authorization, options)(fetch, basePath);
        },
        /**
         *
         * @summary Retries activity specified by id
         * @param {RetryActivityDto} body retryActivityDto
         * @param {string} [authorization] Authorization Header
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        retryActivityUsingPOST: function (body, authorization, options) {
            return (0, exports.PointClickCareControllerApiFp)(configuration).retryActivityUsingPOST(body, authorization, options)(fetch, basePath);
        },
        /**
         *
         * @summary Skips creating PointClickCare account for specified patient/resident
         * @param {CreateAccountDto} body createAccountDto
         * @param {string} [authorization] Authorization Header
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        skipPccAccountUsingPOST: function (body, authorization, options) {
            return (0, exports.PointClickCareControllerApiFp)(configuration).skipPccAccountUsingPOST(body, authorization, options)(fetch, basePath);
        },
        /**
         *
         * @summary Uploads checklist item document to PCC
         * @param {Blob} file
         * @param {string} admissionId admissionId
         * @param {string} checklistItemId checklistItemId
         * @param {number} documentCategoryId documentCategoryId
         * @param {string} [authorization] Authorization Header
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadChecklistItemDocumentUsingPOST: function (file, admissionId, checklistItemId, documentCategoryId, authorization, options) {
            return (0, exports.PointClickCareControllerApiFp)(configuration).uploadChecklistItemDocumentUsingPOST(file, admissionId, checklistItemId, documentCategoryId, authorization, options)(fetch, basePath);
        },
        /**
         *
         * @summary Upload generated packet of given admission and store returned document identifier
         * @param {string} admissionId admissionId
         * @param {string} [authorization] Authorization Header
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadPacketUsingPUT: function (admissionId, authorization, options) {
            return (0, exports.PointClickCareControllerApiFp)(configuration).uploadPacketUsingPUT(admissionId, authorization, options)(fetch, basePath);
        },
        /**
         *
         * @summary Endpoint called once pending PointClickCare account gets active on PointClickCare side
         * @param {string} body payload
         * @param {string} [authorization] Authorization Header
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        webHookUsingPOST: function (body, authorization, options) {
            return (0, exports.PointClickCareControllerApiFp)(configuration).webHookUsingPOST(body, authorization, options)(fetch, basePath);
        },
    };
};
exports.PointClickCareControllerApiFactory = PointClickCareControllerApiFactory;
/**
 * PointClickCareControllerApi - object-oriented interface
 * @export
 * @class PointClickCareControllerApi
 * @extends {BaseAPI}
 */
var PointClickCareControllerApi = /** @class */ (function (_super) {
    __extends(PointClickCareControllerApi, _super);
    function PointClickCareControllerApi() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    /**
     *
     * @summary Checks if PointClickCare integration is enabled
     * @param {string} facilityId facilityId
     * @param {string} [authorization] Authorization Header
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PointClickCareControllerApi
     */
    PointClickCareControllerApi.prototype.checkPccIntegrationStatusUsingGET = function (facilityId, authorization, options) {
        return (0, exports.PointClickCareControllerApiFp)(this.configuration).checkPccIntegrationStatusUsingGET(facilityId, authorization, options)(this.fetch, this.basePath);
    };
    /**
     *
     * @summary Checks if PointClickCare preflight populating flow is enabled for given facility and event type
     * @param {string} eventListenerGroupType eventListenerGroupType
     * @param {string} facilityId facilityId
     * @param {string} [authorization] Authorization Header
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PointClickCareControllerApi
     */
    PointClickCareControllerApi.prototype.checkPreflightPopulatingFlowEnabledUsingGET = function (eventListenerGroupType, facilityId, authorization, options) {
        return (0, exports.PointClickCareControllerApiFp)(this.configuration).checkPreflightPopulatingFlowEnabledUsingGET(eventListenerGroupType, facilityId, authorization, options)(this.fetch, this.basePath);
    };
    /**
     *
     * @summary Check if resident has PointClickCare account
     * @param {string} admissionId admissionId
     * @param {string} facilityId facilityId
     * @param {string} [authorization] Authorization Header
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PointClickCareControllerApi
     */
    PointClickCareControllerApi.prototype.checkResidentPccAccountStatusUsingGET = function (admissionId, facilityId, authorization, options) {
        return (0, exports.PointClickCareControllerApiFp)(this.configuration).checkResidentPccAccountStatusUsingGET(admissionId, facilityId, authorization, options)(this.fetch, this.basePath);
    };
    /**
     *
     * @summary Returns list of residents based on criteria
     * @param {string} birthDate birthDate
     * @param {string} facilityId facilityId
     * @param {string} firstName firstName
     * @param {string} gender gender
     * @param {string} lastName lastName
     * @param {string} [authorization] Authorization Header
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PointClickCareControllerApi
     */
    PointClickCareControllerApi.prototype.findResidentsUsingGET = function (birthDate, facilityId, firstName, gender, lastName, authorization, options) {
        return (0, exports.PointClickCareControllerApiFp)(this.configuration).findResidentsUsingGET(birthDate, facilityId, firstName, gender, lastName, authorization, options)(this.fetch, this.basePath);
    };
    /**
     *
     * @summary Returns facility based activity logs for authenticated users. Pagination is mandatory, default is page=0&size=20&sort=time,desc. Search params: ?facilityId=facilityIdentifier || ?organizationId=organizationIdentifier
     * @param {string} facilityId facilityId
     * @param {string} [authorization] Authorization Header
     * @param {string} [organizationId] organizationId
     * @param {number} [page]
     * @param {number} [size]
     * @param {ReadonlyArray<string>} [sort]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PointClickCareControllerApi
     */
    PointClickCareControllerApi.prototype.getActivityLogRecordsUsingGET = function (facilityId, authorization, organizationId, page, size, sort, options) {
        return (0, exports.PointClickCareControllerApiFp)(this.configuration).getActivityLogRecordsUsingGET(facilityId, authorization, organizationId, page, size, sort, options)(this.fetch, this.basePath);
    };
    /**
     *
     * @summary Returns document upload categories by facility ID
     * @param {string} facilityId facilityId
     * @param {string} [authorization] Authorization Header
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PointClickCareControllerApi
     */
    PointClickCareControllerApi.prototype.getDocumentUploadCategoriesUsingGET = function (facilityId, authorization, options) {
        return (0, exports.PointClickCareControllerApiFp)(this.configuration).getDocumentUploadCategoriesUsingGET(facilityId, authorization, options)(this.fetch, this.basePath);
    };
    /**
     *
     * @summary Returns resident by ID
     * @param {string} facilityId facilityId
     * @param {string} [authorization] Authorization Header
     * @param {string} [admissionId] admissionId
     * @param {number} [patientId] patientId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PointClickCareControllerApi
     */
    PointClickCareControllerApi.prototype.getResidentUsingGET = function (facilityId, authorization, admissionId, patientId, options) {
        return (0, exports.PointClickCareControllerApiFp)(this.configuration).getResidentUsingGET(facilityId, authorization, admissionId, patientId, options)(this.fetch, this.basePath);
    };
    /**
     *
     * @summary Create new PointClickCare account and appropriate mapping
     * @param {CreateAccountDto} body createAccountDto
     * @param {string} [authorization] Authorization Header
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PointClickCareControllerApi
     */
    PointClickCareControllerApi.prototype.requestNewPccAccountUsingPOST = function (body, authorization, options) {
        return (0, exports.PointClickCareControllerApiFp)(this.configuration).requestNewPccAccountUsingPOST(body, authorization, options)(this.fetch, this.basePath);
    };
    /**
     *
     * @summary Retries activity specified by id
     * @param {RetryActivityDto} body retryActivityDto
     * @param {string} [authorization] Authorization Header
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PointClickCareControllerApi
     */
    PointClickCareControllerApi.prototype.retryActivityUsingPOST = function (body, authorization, options) {
        return (0, exports.PointClickCareControllerApiFp)(this.configuration).retryActivityUsingPOST(body, authorization, options)(this.fetch, this.basePath);
    };
    /**
     *
     * @summary Skips creating PointClickCare account for specified patient/resident
     * @param {CreateAccountDto} body createAccountDto
     * @param {string} [authorization] Authorization Header
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PointClickCareControllerApi
     */
    PointClickCareControllerApi.prototype.skipPccAccountUsingPOST = function (body, authorization, options) {
        return (0, exports.PointClickCareControllerApiFp)(this.configuration).skipPccAccountUsingPOST(body, authorization, options)(this.fetch, this.basePath);
    };
    /**
     *
     * @summary Uploads checklist item document to PCC
     * @param {Blob} file
     * @param {string} admissionId admissionId
     * @param {string} checklistItemId checklistItemId
     * @param {number} documentCategoryId documentCategoryId
     * @param {string} [authorization] Authorization Header
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PointClickCareControllerApi
     */
    PointClickCareControllerApi.prototype.uploadChecklistItemDocumentUsingPOST = function (file, admissionId, checklistItemId, documentCategoryId, authorization, options) {
        return (0, exports.PointClickCareControllerApiFp)(this.configuration).uploadChecklistItemDocumentUsingPOST(file, admissionId, checklistItemId, documentCategoryId, authorization, options)(this.fetch, this.basePath);
    };
    /**
     *
     * @summary Upload generated packet of given admission and store returned document identifier
     * @param {string} admissionId admissionId
     * @param {string} [authorization] Authorization Header
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PointClickCareControllerApi
     */
    PointClickCareControllerApi.prototype.uploadPacketUsingPUT = function (admissionId, authorization, options) {
        return (0, exports.PointClickCareControllerApiFp)(this.configuration).uploadPacketUsingPUT(admissionId, authorization, options)(this.fetch, this.basePath);
    };
    /**
     *
     * @summary Endpoint called once pending PointClickCare account gets active on PointClickCare side
     * @param {string} body payload
     * @param {string} [authorization] Authorization Header
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PointClickCareControllerApi
     */
    PointClickCareControllerApi.prototype.webHookUsingPOST = function (body, authorization, options) {
        return (0, exports.PointClickCareControllerApiFp)(this.configuration).webHookUsingPOST(body, authorization, options)(this.fetch, this.basePath);
    };
    return PointClickCareControllerApi;
}(BaseAPI));
exports.PointClickCareControllerApi = PointClickCareControllerApi;
